/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/triage";
const triageService = {
    updatetriagedalert: async (alert) => {
        const response = await http.post(`${controller}/updatetriagedalert`, alert);
        return response.data;
    },
    getusertriagemetrics: async (userId) => {
        const response = await http.get(`${controller}/getusertriagemetrics?userId=${userId}`);
        return response.data;
    },
    getdailytriagemetrics: async (userId) => {
        const response = await http.get(`${controller}/getdailytriagemetrics`);
        return response.data;
    },
    gettriagedashboarddata: async () => {
        const response = await http.get(`${controller}/gettriagedashboarddata`);
        return response.data;
    },
    getalltriageusers: async () => {
        const response = await http.get(`${controller}/getalltriageusers`);
        return response.data;
    },
    createtriagesession: async (session) => {
        const response = await http.post(`${controller}/createtriagesession`, session);
        return response.data;
    },
    publishtriagesession: async (session) => {
        const response = await http.post(`${controller}/publishtriagesession`, session);
        return response;
    },
    updatesessionendtime: async (sessionId, endStr) => {
        const response = await http.post(`${controller}/updatesessionendtime?sessionId=${sessionId}&endStr=${endStr}`);
        return response.data;
    }
};
export default triageService;
