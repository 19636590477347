/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import axios from "axios";
import CryptoJS from "crypto-js";
import { withStyles } from '@material-ui/core/styles';

//import { Event, PageView, ModalView, Timing } from "../GoogleAnalytics";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});


const DEV_IOT_URL = "tt-org-lwdev.azure-devices.net/devices/";
const DEV_IOT_ORG_ID = "00391A11531F-51EF-4847-B5A1-B54D7F354FF3";
const DEV_KEY_VALUE = "fe8mkf3URf7oIIfsbB6aE5buvbQFiq90llF/km6zBYI=";
const DEV_DEFAULT_DEVICE_ID = "FFFFFF000000";

const IOT_URL = "tt-org-dev01-hub.azure-devices.net/devices/";
const IOT_ORG_ID = "0004010151A67C121AB44152BFEEF9C2AC21D5B0";
const KEY_VALUE = "Ls14J2q5BbuCwlClmVUI9sqOURSXoQG1t2Oeto+GwCI=";
const DEFAULT_DEVICE_ID = "001ec033b61b";

const IOT_DEST = "/messages/events?api-version=2020-03-13";
const KEY_EXPIRY = 60;

class IotTest extends Component {

    static displayName = IotTest.name;


    constructor(props) {
        super(props);

        this.testD2C = this.testD2C.bind(this);

        this.onChangeDeviceId = this.onChangeDeviceId.bind(this);
        this.onChangeRunCount = this.onChangeRunCount.bind(this);

        this.isNumberKey = this.isNumberKey.bind(this);

        this.onENVChange = this.onENVChange.bind(this);

        this.state = {
            runCount: 1,
            displayCounterSuccess: 0,
            displayCounterError: 0,
            deviceId: DEFAULT_DEVICE_ID,
            dataDisplay: "",
            iotOrgId: IOT_ORG_ID,
            iotURL: IOT_URL,
            iotKeyValue: KEY_VALUE
        };
    }

    onENVChange(e) {
        var s = e.target.value;
        this.setState({
            displayCounterError: 0,
            displayCounterSuccess: 0,
            iotOrgId: (s === "DEV" ? DEV_IOT_ORG_ID : IOT_ORG_ID),
            iotURL: (s === "DEV" ? DEV_IOT_URL : IOT_URL),
            iotKeyValue: (s === "DEV" ? DEV_KEY_VALUE : KEY_VALUE),
            deviceId: (s === "DEV" ? DEV_DEFAULT_DEVICE_ID : DEFAULT_DEVICE_ID)
        });
    }

    isNumberKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode < 48 || charCode > 57)) {
            return false;
        }

        return true;
    }

    generateToken(deviceId) {
        let resourceUri = this.state.iotURL + deviceId;

        let targetUri = resourceUri;

        let timestamp = Date.now();

        let expiryTime = KEY_EXPIRY;

        expiryTime = Math.ceil((parseInt(expiryTime) + parseInt(timestamp)) / 1000);

        let toSign = resourceUri + '\n' + expiryTime;

        let decodedKey = CryptoJS.enc.Base64.parse(this.state.iotKeyValue);

        let hash = CryptoJS.HmacSHA256(toSign, decodedKey);
        let base64HashValue = CryptoJS.enc.Base64.stringify(hash);
        let encodedUri = encodeURIComponent(base64HashValue);

        let sasToken = "SharedAccessSignature sig=" + encodedUri + "&se=" + expiryTime + "&skn=device&sr=" + targetUri;

        return sasToken;
    }

    async testD2C(deviceId) {

        this.setState({ displayCounterSuccess: 0, displayCounterError: 0 }, async () => {
            let successCount = 0;
            let errorCount = 0;

            let thisRunCount = 0;
           
            while (thisRunCount < this.state.runCount) {

                let urll = 'https://' + this.state.iotURL + deviceId + IOT_DEST;

                await axios({
                    method: 'post',
                    url: urll,
                    headers: { Authorization: this.generateToken(deviceId) },
                    data: { 'OrgUI': this.state.iotOrgId, 'fmt': 20, bkTestSensorMap29: { 'DataType': 1, 'Points': [{}] } }
                }).then((response) => {
                    //var response = response.data;
                    thisRunCount = thisRunCount + 1;
                    successCount = successCount + 1;
                    this.setState({ displayCounterSuccess: successCount, dataDisplay: response.data });
                }, (error) => {
                    thisRunCount = thisRunCount + 1;
                    errorCount = errorCount + 1;
                    this.setState({ displayCounterError: errorCount, dataDisplay: error });
                });
            }

        });        
    }

    onChangeDeviceId(e) {
        let newDeviceId = e.target.value;

        if (newDeviceId !== "" && newDeviceId !== undefined && newDeviceId !== null) {
            this.setState({ deviceId: newDeviceId });
        }
    }

    onChangeRunCount(e) {
        let newRunCount = parseInt(e.target.value);

        if (newRunCount !== "" && newRunCount !== undefined && newRunCount !== null) {
            this.setState({ runCount: newRunCount });
        }
    }

    render() {

        const { classes } = this.props;


        return (
            <div className={classes.root}>
                <div className='infoPage'>
                <h1>Hello, IoT Tester!</h1>
                    <p>Welcome to the IoT test application</p>

                    <div>
                        <p>Select environment:</p>
                        <select onChange={this.onENVChange} style={{ width:"100px" }}>
                            <option value="PROD">PROD</option>
                            <option value="DEV">DEV</option>
                        </select>
                    </div>
                    <br/>

                <div>
                    Enter the deviceId to send:
                      <input
                        type="text"
                        className="form-control"
                        id="deviceId"
                        value={this.state.deviceId}
                        onChange={this.onChangeDeviceId}
                    />

                    Enter the number of requests to send:
                      <input
                        type="tel"
                        className="form-control"
                        id="runCount"
                        value={this.state.runCount}
                        pattern="\d*"
                        maxLength="2"
                        onKeyPress={this.isNumberKey}
                        onChange={this.onChangeRunCount}
                    />
                </div>
                <br/>
                <label>Run counter</label><br />
                <div>
                    <span>Success count: </span>
                    {this.state.displayCounterSuccess}
                </div>
                <div>
                    <span>Error count: </span>
                    {this.state.displayCounterError}
                    </div>

                <button
                    type="button"
                    className="btn-main"
                    onClick={() => this.testD2C(this.state.deviceId)}
                >
                    Test
                </button>

                </div>
            </div>
        );
    }
}
export default withStyles(styles)(IotTest);
