/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AlertService from '../../services/alerts.service';
import AssetService from '../../services/asset.service';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ReferenceArea, ReferenceDot, ReferenceLine, ResponsiveContainer, CartesianGrid } from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { RunRightPanel } from '../Graph/RunRightPanel';
import { ACRightPanel } from '../Graph/ACRightPanel';
import HighchartGraph from '../Graph/HighchartGraph';
import { Event } from '../GoogleAnalytics';
import { jsSource } from "../../componentObjects";
import 'react-tabs/style/react-tabs.css';
import "../../CSS/DataModal.css";
import moment from 'moment';

class AlertGraphsModal extends Component {

    constructor(props) {
        super(props);

        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.onChangeRun = this.onChangeRun.bind(this);
        this.onRightRunClick = this.onRightRunClick.bind(this);
        this.onLeftRunClick = this.onLeftRunClick.bind(this);
        this.runAnalytic = this.runAnalytic.bind(this);
        this.onSetpointClick = this.onSetpointClick.bind(this);
        this.getHighchartsData = this.getHighchartsData.bind(this);

        this.state = {
            acSelected: false,
            alertSelected: false,
            canLeftClick: false,
            canRightClick: false,
            currentRunName: "",
            currentSource: jsSource,
            selectedRun: 0,
            showRunAnnotations: false,
            showZoomButtom: false,
            visibility: {
                analytics: false,
                furnace: true,
                temp: true,
                rla: true,
                lra: true,
                blowerCurrent: false,
                inducerCurrent: false,
                coolSetpoint: false,
                heatSetpoint: false

            },
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.props.clearGraphData();
    };

    showZoomButton(show) {
        this.setState({ showZoomButton: show });
    }

    runAnalytic() {
        this.setState({ showRunAnnotations: !this.state.showRunAnnotations }, () => {
            if (this.state.showRunAnnotations)
                Event("Run Analytics Clicked", "User clicked run analytics on alert graph", "Run Analytics Clicked");
        });
    }

    onSetpointClick(spType) {
        if (spType == "cool")
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    coolSetpoint: !this.state.visibility.coolSetpoint
                }
            }));
        else if (spType == "heat")
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    heatSetpoint: !this.state.visibility.heatSetpoint
                }
            }));
    }

    handleCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: false
                }
            }));
        }
    }

    async onRightRunClick() {
        let numRuns = this.props.currentGraph.timeSeriesDataSources.length;
        let index = this.state.selectedRun + 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.props.currentGraph.runNamesList[index],
            currentSource: this.props.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });

    }

    async onLeftRunClick() {
        let numRuns = this.props.currentGraph.timeSeriesDataSources.length;
        let index = this.state.selectedRun - 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.props.currentGraph.runNamesList[index],
            currentSource: this.props.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });
    }

    async onChangeRun(e) {
        let numRuns = this.props.currentGraph.timeSeriesDataSources.length;
        let index = parseInt(e.target.value, 10);
        let canLeft = index > 0 ? true : false;
        let canRight = index < numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.props.currentGraph.runNamesList[index],
            currentSource: this.props.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });
    }

    getHighchartsAnnotations(graphAnnotations) {
        var plotBands = [];

        if (graphAnnotations !== null && graphAnnotations !== undefined && graphAnnotations.length) {
            for (let i = 0; i < graphAnnotations.length; i++) {
                let annotation = graphAnnotations[i];
                let bandStart = moment(annotation["startTime"]).valueOf();
                let bandEnd = moment(annotation["endTime"]).valueOf();

                let band = {
                    color: annotation["color"] + "45", /* opacity */
                    from: bandStart,
                    to: bandEnd,
                    label: {
                        text: annotation["state"],
                        rotation: -90,
                        textAlign: 'left',
                        verticalAlign: 'top',
                        y: 90
                    },
                    zIndex: 1
                };

                plotBands = plotBands.concat(band);
            }
        }

        return plotBands;
    }

    getHighchartsData(currentHCSource) {
        var chartDataList = [];
        var fullChartDataList = currentHCSource;
        if (fullChartDataList != null && fullChartDataList != undefined) {
            var acCurrent = fullChartDataList?.find(x => x.name == "AC Current");
            if (acCurrent != null && acCurrent != undefined) {
                chartDataList = chartDataList.concat(acCurrent);
            }


            if (this.state.visibility.temp) {
                var indoorHubTemp = fullChartDataList?.find(x => x.name == "Indoor Hub Temp");
                if (indoorHubTemp != null && indoorHubTemp != undefined) {
                    chartDataList = chartDataList.concat(indoorHubTemp);
                }
                var outdoorHubTemp = fullChartDataList?.find(x => x.name == "Outdoor Hub Temp");
                if (outdoorHubTemp != null && outdoorHubTemp != undefined) {
                    chartDataList = chartDataList.concat(outdoorHubTemp);
                }
            }
            if (this.state.visibility.furnace) {
                var furenceCurrent = fullChartDataList?.find(x => x.name == "Furnace Current");
                if (furenceCurrent != null && furenceCurrent != undefined) {
                    chartDataList = chartDataList.concat(furenceCurrent);
                }

            }
        }
        return chartDataList;
    }

    renderACGraph(isRunPanel) {
        let hasAnalytics = this.props.currentAsset.hasAnalytics;
        let currentSource = this.props.currentGraph.highchartsRun; //.timeSeriesDataSources[0]; //this.state.currentSource ? this.state.currentSource : this.props.currentGraph.timeSeriesDataSources[0];
        let canRightClick = (this.state.selectedRun == 0 && this.props.currentGraph.timeSeriesDataSources !== null && this.props.currentGraph.timeSeriesDataSources.length) ? true : this.state.canRightClick;
        
        let acRunPanel = currentSource !== null && currentSource !== undefined && currentSource.data !== undefined && currentSource.data.length ?
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12 col-md-8">
                    <div className="highlight-bar-charts" >
                        <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsData(this.props.runDataList)} key={`${this.props.currentAsset.assetId}-${currentSource.timeString}-${this.props.runDataList.length}-${this.state.visibility}`} isAirConditioner={true}
                            showRLA={this.state.visibility.rla} rlaValue={this.props.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.props.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                            showAnalytics={this.state.visibility.analytics} hasAnalytics={this.props.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={currentSource.name} pointStart={currentSource.data[0]} />

                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <ACRightPanel isFullScreen={true} isDaily={false} showAnnotations={this.state.showRunAnnotations} onClick1={this.runAnalytic} onClick2={() => { this.child.zoomOut(); }} visibility={this.state.visibility}
                        handleCheckbox={this.handleCheckbox} zoomOutEnabled={this.state.showZoomButton} hasAnalytics={this.props.currentAsset.hasAnalytics == true ? true : false} canLeftClick={this.state.canLeftClick == true ? true : false} canRightClick={canRightClick == true ? true : false}
                        runNamesList={this.props.currentGraph.runNamesList} selectedRun={this.state.selectedRun} onChangeRun={this.onChangeRun} onLeftRunClick={this.onLeftRunClick} onRightRunClick={this.onRightRunClick} />
                </div>
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let acDailyPanel = this.props.currentGraph !== undefined && this.props.currentGraph.highchartsDaily !== null && this.props.currentGraph.highchartsDaily !== undefined && this.props.currentGraph.highchartsDaily.data.length && this.props.timeLineDataList.length ?
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12 col-md-8">
                    <div className="highlight-bar-charts" >
                        <HighchartGraph graphType={"ASSET"} chartData={this.props.timeLineDataList} key={`${this.props.currentGraph.highchartsDaily.name}-${this.state.visibility}-timeline`} isAirConditioner={true} 
                            showRLA={this.state.visibility.rla} rlaValue={this.props.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.props.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.props.currentGraph.daily.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                            showAnalytics={this.state.visibility.analytics} hasAnalytics={hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={currentSource.name} pointStart={this.props.currentGraph.highchartsDaily.data[0]} />

                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <ACRightPanel isFullScreen={true} isDaily={true} showAnnotations={this.state.showRunAnnotations} onClick1={this.runAnalytic} onClick2={() => { this.child.zoomOut(); }}
                        handleCheckbox={this.handleCheckbox} visibility={this.state.visibility} zoomOutEnabled={this.state.showZoomButton} hasAnalytics={hasAnalytics} />
                </div>
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let acPanel = isRunPanel ? acRunPanel : acDailyPanel;

        return (acPanel);
    }

    renderBody() {
        let hasAnalytics = this.props.currentAsset.hasAnalytics;
        let currentSource = this.props.currentGraph; //this.state.currentSource ? this.state.currentSource : this.props.currentGraph.timeSeriesDataSources[0];
        let acGraph = this.props.currentAlert.assetClassNames.some(a => a === "AirConditioner") ? true : false;
        
        let multiGraph = this.props.multiseriesGraph !== null && this.props.multiseriesGraph !== undefined && this.props.multiseriesGraph.series !== null && this.props.multiseriesGraph.series !== undefined && this.props.multiseriesGraph.series[0] !== undefined && this.props.multiseriesGraph.series[0] !== null && this.props.multiseriesGraph.series[0].data[0] !== undefined && this.props.multiDataList.length ?
            <div>
                <HighchartGraph graphType={"MULTISERIES"} chartData={this.props.multiDataList} key={`Multiseries-${this.props.multiseriesGraph.series.length}`} graph={this.props.multiseriesGraph} isAirConditioner={true} dataMax={this.props.multiseriesGraph.yDataMax}
                    showRLA={false} rlaValue={this.props.currentGraph.rla} showLRA={false} lraValue={this.props.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.props.multiseriesGraph.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={null} pointStart={this.props.multiseriesGraph.series[0].data[0].timestamp} />
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let runPanel = acGraph ?
            <div>{this.renderACGraph(true)}</div>
            : (this.props.currentGraph.run !== null && this.props.currentGraph.run !== undefined && this.props.currentGraph.run.series !== null && this.props.currentGraph.run.series !== undefined && this.props.currentGraph.run.series[0] !== null && this.props.currentGraph.run.series[0] !== undefined && this.props.currentGraph.run.series[0].data[0] !== undefined && this.props.runDataList.length ?
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-sm-12 col-md-10">
                        <div className="highlight-bar-charts" >
                            <HighchartGraph graphType={"ASSET"} chartData={this.props.runDataList} key={`${currentSource !== undefined ? currentSource.timeString : null}-${this.props.runDataList.length}-${this.state.visibility}`} isAirConditioner={false} dataMax={this.props.currentGraph.run.yDataMax}
                                showRLA={this.state.visibility.rla} rlaValue={this.props.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.props.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.props.currentGraph.run.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                                showAnalytics={this.state.visibility.analytics} hasAnalytics={hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} showBlower={this.state.visibility.blowerCurrent} showInducer={this.state.visibility.inducerCurrent}
                                timeString={currentSource !== undefined ? currentSource.timeString : ""} pointStart={this.props.currentGraph.run.series[0].data[0].timestamp} isMultiCT={this.props.currentAsset.isMultiCT} />
                                
                            <br /><br />
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-2" style={{ marginTop: "25px" }}>
                        <RunRightPanel isFullScreen={true} showAnnotations={this.state.showRunAnnotations}
                            onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }} handleCheckbox={this.handleCheckbox}
                            zoomOutEnabled={this.state.showZoomButton} hasAnalytics={hasAnalytics} />
                    </div>
                </div>
                : <div className="centered"><br />No Data found!</div>);

        let dailyPanel = acGraph ?
            <div>{this.renderACGraph(false)}</div>
            : (this.props.currentGraph.daily !== null && this.props.currentGraph.daily !== undefined && this.props.currentGraph.daily.series !== null &&
                this.props.currentGraph.daily.series !== undefined && this.props.currentGraph.daily.series[0] !== null && this.props.currentGraph.daily.series[0] !== undefined &&
                this.props.currentGraph.daily.series[0].data[0] !== undefined && this.props.timeLineDataList.length ?

                (this.props.isThermostat ?
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-sm-12 col-md-10">
                            <HighchartGraph graphType={"ASSET"} chartData={this.props.timeLineDataList} key={`${this.props.currentGraph.daily.timeString}-${this.props.timeLineDataList.length}-${this.state.visibility}-timeline`} isAirConditioner={false} isThermostat={true} dataMax={this.props.currentGraph.daily.yDataMax} yDataLabel={this.props.currentGraph.daily.yDataLabel}
                                showRLA={this.state.visibility.rla} rlaValue={this.props.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.props.currentGraph.run.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.props.currentGraph.daily.graphAnnotations)} showAnnotations={true/*this.state.showRunAnnotations*/}
                                showAnalytics={this.state.visibility.analytics} hasAnalytics={hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} showCoolSetpoint={this.state.visibility.coolSetpoint} showHeatSetpoint={this.state.visibility.heatSetpoint}
                                    timeString={this.props.currentGraph.daily.timeString} pointStart={this.props.currentGraph.daily.series[0].data[0].timestamp} showBlower={this.state.visibility.blowerCurrent} showInducer={this.state.visibility.inducerCurrent} isMultiCT={this.props.currentAsset.isMultiCT} />

                            <br /><br />
                        </div>
                        <div className="col-sm-4 col-md-2" style={{ marginTop: "25px" }}>
                            <RunRightPanel isFullScreen={true} showAnnotations={this.state.showRunAnnotations}
                                onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }}
                                onClick3={() => this.onSetpointClick} isThermostat={this.props.isThermostat}
                                    zoomOutEnabled={this.state.showZoomButton} hasAnalytics={hasAnalytics} handleCheckbox={this.handleCheckbox} />
                            <br /><br />
                        </div>
                    </div>
                    : <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-sm-12 col-md-10">
                            <HighchartGraph graphType={"ASSET"} chartData={this.props.timeLineDataList} key={`${this.props.currentGraph.daily.timeString}-${this.props.timeLineDataList.length}-${this.state.visibility}-timeline`} isAirConditioner={false} dataMax={this.props.currentGraph.daily.yDataMax}
                                showRLA={this.state.visibility.rla} rlaValue={this.props.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.props.currentGraph.run.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.props.currentGraph.daily.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                                showAnalytics={this.state.visibility.analytics} hasAnalytics={hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.props.currentGraph.daily.timeString} pointStart={this.props.currentGraph.daily.series[0].data[0].timestamp} />

                            <br /><br />
                        </div>
                        <div className="col-sm-4 col-md-2" style={{ marginTop: "25px" }}>
                            <RunRightPanel isFullScreen={true} showAnnotations={this.state.showRunAnnotations}
                                onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }}
                                zoomOutEnabled={this.state.showZoomButton} hasAnalytics={hasAnalytics} />
                            <br /><br />
                        </div>
                    </div>)
                : <div className="centered"><br />No Data found!</div>);

        let noDataStr = this.props.currentGraph ? "No Data Found!" : "Select an alert to display graph here";

        if (this.props.multiseriesGraph !== undefined && this.props.multiseriesGraph !== null && this.props.multiseriesGraph.series !== null && this.props.multiseriesGraph.series !== undefined) {
            return (
                <div style={{ marginRight: 25 + "px" }}>
                    <Tabs>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Trend</Tab>
                            <Tab>Run</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="row">
                                <div className="col-sm-12 col-md-10">
                                    <div style={{ marginTop: 10 + "px", marginRight: 15 + "px" }}>
                                        {multiGraph}
                                        <br /><br />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <RunRightPanel isFullScreen={true} showAnnotations={false}
                                        onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }} handleCheckbox={this.handleCheckbox}
                                        zoomOutEnabled={this.state.showZoomButton} hasAnalytics={hasAnalytics} />
                                    <br /><br />
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel>
                            {runPanel}
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
        else if (this.props.isThermostat && Object.keys(this.props.currentGraph).length && !this.props.graphLoading && this.props.currentGraph !== undefined && this.props.currentGraph !== null && (this.props.currentGraph.daily !== null)) {
            return (
                <div style={{ marginRight: 25 + "px" }}>
                    <Tabs>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Timeline</Tab>
                        </TabList>

                        <TabPanel>
                            {dailyPanel}
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
        else if (Object.keys(this.props.currentGraph).length && !this.props.graphLoading && this.props.currentGraph !== undefined && this.props.currentGraph !== null && ((acGraph ? currentSource !== null : this.props.currentGraph.run !== null) || this.props.currentGraph.daily !== null)) {
            return (
                <div style={{ marginRight: 25 + "px" }}>
                    <Tabs>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Timeline</Tab>
                        </TabList>

                        <TabPanel>
                            {runPanel}
                        </TabPanel>

                        <TabPanel>
                            {dailyPanel}
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }

        else {
            return (
                <div style={{ marginTop: 15 + "px", marginRight: 25 + "px" }}>
                    <Tabs>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Timeline</Tab>
                            <Tab>Trend</Tab>
                        </TabList>

                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }

        return (
            <Tabs>
                <TabList>
                    <Tab>Run</Tab>
                    <Tab>Timeline</Tab>
                </TabList>

                <TabPanel>
                    {runPanel}
                </TabPanel>

                <TabPanel>
                    {dailyPanel}
                </TabPanel>
            </Tabs>
        );
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {

            return (
                <div className="fullscreen-modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="big-modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header" >
                            <h3>Alert Graphs</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "center", justifyContent: "center" }}>

                            {this.props.graphLoading ?
                                <div className='centered' style={{ minHeight: '300px', alignItems: 'center' }}><Spinner animation="border" variant="dark" /></div>
                                : this.renderBody()
                            }

                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Close</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default AlertGraphsModal;