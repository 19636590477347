/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import Highcharts from 'highcharts';
import AlertService from '../../services/alerts.service';
import { Event } from "../GoogleAnalytics";

import moment from 'moment';

require('highcharts/modules/boost')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

class Graph extends Component {

    constructor(props) {
        super(props);

        this.chartContainer = React.createRef();  
        this.state = {
            options: this.props.options
        }
    }

    componentDidMount() {
        this.chart = new Highcharts[this.props.type || 'Chart'](
            this.chartContainer.current,
            this.props.options
        );
    }

    componentWillUnmount() {
        this.chart.destroy();
    }

    render() {
        return <div ref={this.chartContainer} />;
    }
}

export default class HighchartGraph extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.changeRadio = this.changeRadio.bind(this);
        this.getOptions = this.getOptions.bind(this);

        this.state = {
            viewType: "WholeRun",
            right: 0,
            rawData: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    afterZoom() {
        Event("User Zoomed In On Graph", "User zoomed in on graph", "User Zoomed In On Graph");
    }

    changeRadio(e) {
        let x = e.target.value;

        this.setState({ viewType: x }, async () => {
            if (this.state.viewType === "RawData") {
                this.props.setLoading(true);
                await AlertService.getalertrawdata(this.props.assetId, this.props.alertList)
                    .then(response => {
                        let respData = response.data;
                        let rawDataList = [];

                        for (let i = 0; i < this.props.alertList.length; i++) {
                            let alert = this.props.alertList[i];
                            let series = respData[alert.alertId];

                            let rawArr = series.data.map((item) => (item.value));
                            let rawData = {
                                name: `${alert.alertId}`,
                                data: rawArr,
                                color: series.color,
                                dashStyle: 'solid',
                                type: 'line',
                                opacity: 1,
                                marker: {
                                    enabledThreshold: 0
                                }
                            };
                            rawDataList = rawDataList.concat(rawData);
                        }

                        this.setState({ rawData: rawDataList }, () => {
                            this.props.setLoading(false);
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        });
    }

    getOptions(chartData) {
        let graphType = this.props.graphType;
        let seriesArray = [];
        seriesArray = this.props.chartData;

        let titleStr = graphType === "ASSET" || graphType === "TRIAGEALERTS" ? this.props.timeString : "";
        let noDataStr = graphType === "ALERT" ? "No graphed alerts" : (graphType === "ASSET" ? "Select an asset to display graph" : "");

        let newOptions = {
            title: {
                text: titleStr,
            },
            time: {
                useUTC: false
            },
            yAxis: {
                offset: 10,
                crosshair: true,
                title: {
                    text: 'Amps',
                }
            },
            xAxis: {},
            chart: {
                type: 'line',
                zoomBySingleTouch: true,
                zoomType: 'x',
                accessibility: false
            },
            series: seriesArray,
            plotOptions: {
                series: {
                    lineWidth: 1,
                    findNearestPointBy: 'xy',
                    marker: {
                        enabled: false
                    }
                }
            },
            lang: {
                noData: noDataStr
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030'
                }
            }
        };

        if (this.props.isThermostat) {
            newOptions.yAxis.title.text = this.props.yAxisLabel;
        }

        if (graphType === "ALERT") {
            if (this.state.viewType === "RawData") {
                newOptions.series = this.state.rawData;
                seriesArray = this.state.rawData;
            }
            else if (this.state.viewType === "Last150") {
                newOptions.series = this.props.last150Data;
                seriesArray = this.props.last150Data;
            }

            if (this.state.viewType !== "WholeRun") {
                newOptions.xAxis.max = this.state.viewType === "RawData" ? this.props.rawDataMax : 150;
            }
            else {
                newOptions.xAxis.max = this.props.dataMax;
	       }


            if (this.props.isTraining) {
                if (this.props.showAnnotations && this.props.graphAnnotations != null && this.props.graphAnnotations !== undefined && this.props.graphAnnotations.length) {
                    newOptions.xAxis.plotBands = this.props.graphAnnotations;
                }
            }

        }

        if (graphType === "ASSET" || graphType === "TRIAGEALERTS") {
            let tickInterval = 60;
            if (seriesArray != null && seriesArray !== undefined && seriesArray.length && seriesArray[0] !== undefined && seriesArray[0].data !== undefined && seriesArray[0].data.length > 5) {
                tickInterval = seriesArray[0].data.length / 6;
            }

            newOptions.xAxis = {
                type: 'datetime',
                tickInterval: 1000 * tickInterval,
                labels: {
                    format: '{value:%l:%M:%S %p}',
                    align: 'center'
                },
                offset: 10,
                crosshair: true,
                minPadding: 0.03,
                maxPadding: 0.02
            };

            if (this.props.pointStart !== null && this.props.pointStart !== undefined) {
                var pointStartMoment = graphType === "ASSET" ? moment(this.props.pointStart[0]) : moment(this.props.pointStart);
                newOptions.plotOptions.series.pointStart = pointStartMoment;
            }

            if (this.props.showAnnotations && this.props.graphAnnotations != null && this.props.graphAnnotations !== undefined && this.props.graphAnnotations.length) {
                newOptions.xAxis.plotBands = this.props.graphAnnotations;
            }

            newOptions.tooltip = {
                xDateFormat: '%A %b %e, %l:%M:%S %p'
            };
        }
        else if (graphType === "MULTISERIES") {
            newOptions.xAxis = {
                type: 'datetime',
                tickInterval: 1000 * 3600 * 24,
                labels: {
                    format: '{value:%m/%d/%Y}',
                    align: 'center'
                },
                offset: 10,
                crosshair: true,
                minPadding: 0.03,
                maxPadding: 0.02
            };

            if (this.props.showAnnotations && this.props.graphAnnotations != null && this.props.graphAnnotations !== undefined && this.props.graphAnnotations.length) {
                newOptions.xAxis.plotBands = this.props.graphAnnotations;
            }

            if (this.props.pointStart !== null && this.props.pointStart !== undefined) {
                var pointStartMoment2 = moment(this.props.pointStart);
                newOptions.plotOptions.series.pointStart = pointStartMoment2;
            }

            if (this.props.graph !== null && this.props.graph !== undefined && this.props.graph.series !== null && this.props.graph.series !== undefined && this.props.graph.series[0] !== undefined && this.props.graph.series[0].referenceLines !== undefined && this.props.graph.series[0].referenceLines.length) {
                let plotLines = [];
                let newMax = this.props.dataMax;
                let newMin = this.props.graph.yDataMin;

                for (let i = 0; i < this.props.graph.series[0].referenceLines.length; i++) {
                    let refInfo = this.props.graph.series[0].referenceLines[i];

                    if (refInfo.value > newMax)
                        newMax = refInfo.value;
                    if (refInfo.value < newMin)
                        newMin = refInfo.value;

                    let refLine = {
                        color: refInfo.color,
                        width: 2,
                        value: refInfo.value,
                        label: {
                            text: refInfo.name,
                            align: 'right',
                            x: -10
                        },
                        zIndex: 2
                    };
                    plotLines = plotLines.concat(refLine);
                }
                newOptions.yAxis.plotLines = plotLines;
                if (newMax > 0)
                    newOptions.yAxis.max = newMax;
                newOptions.yAxis.min = newMin;
            }
        }

        if (this.props.isAirConditioner && graphType !== "MULTISERIES") {
            let plotLines = [];
            let newMax = 0; //this.props.dataMax;

            if (this.props.showRLA) {
                let rlaVal = this.props.rlaValue;
                if (rlaVal > newMax)
                    newMax = rlaVal;

                let rlaLine = {
                    color: '#f0b169',
                    width: 2,
                    value: rlaVal,
                    label: {
                        text: 'RLA',
                        align: 'right',
                        x: -10
                    },
                    zIndex: 2
                };

                if (graphType === "ASSET") {
                    rlaLine.dashStyle = 'longDash';
                }
                plotLines = plotLines.concat(rlaLine);
            }

            if (this.props.showLRA) {
                let lraVal = this.props.lraValue;
                if (lraVal > newMax)
                    newMax = lraVal;

                let lraLine = {
                    color: 'red',
                    width: 2,
                    value: lraVal,
                    label: {
                        text: 'LRA',
                        align: 'right',
                        x: -10
                    },
                    zIndex: 2
                };

                if (graphType === "ASSET") {
                    lraLine.dashStyle = 'longDash';
                    plotLines = plotLines.concat(lraLine);
                }

                if (this.props.showFLA) {
                    let flaVal = this.props.flaValue;
                    if (flaVal > newMax)
                        newMax = flaVal;

                    let flaLine = {
                        color: 'pink',
                        width: 2,
                        value: flaVal,
                        label: {
                            text: 'FLA',
                            align: 'right',
                            x: -10
                        },
                        zIndex: 2
                    };
                    plotLines = plotLines.concat(flaLine);
                }

                newOptions.yAxis.plotLines = plotLines;
                if (newMax > 0)
                    newOptions.yAxis.max = newMax;
            }
        }
        if ((this.props.ShowAnalyzeIgnitionData) || (this.props.ShowanAlyzeShutdownData)) {
            let plotLines = [];
            let newMin = 0;
            let newMax = 0;
            let basevalue = 0;
            if (chartData.length > 0) {
                basevalue = Math.min(...chartData[0]?.data);
            }
            if (basevalue === null || basevalue === undefined) { basevalue = 0; }

            if (this.props.AnalyzeData?.furnaceFinalResult?.furnaceProperty !== undefined) {
                let IgnitionProps = Object.keys(this.props.AnalyzeIgnitionData).concat(Object.keys(this.props.AnalyzeShutdownData));
                if (IgnitionProps.length > 0) {
                    for (let i = 0; i < IgnitionProps.length; i++) {
                        if (this.props.AnalyzeIgnitionData[IgnitionProps[i]] || this.props.AnalyzeShutdownData[IgnitionProps[i]]) {

                            const setValue = basevalue + this.props.AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]];
                            let flaLine = {
                                color: 'pink',
                                width: 2,
                                value: setValue,
                                label: {
                                    text: IgnitionProps[i] + "," + setValue,
                                    align: 'right',
                                    x: -10
                                },
                                zIndex: 2
                            };
                            if (newMin > this.props.AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]])
                                newMin = this.props.AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]];
                            if (newMax < this.props.AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]])
                                newMax = this.props.AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]];
                            plotLines = plotLines.concat(flaLine);
                        }
                    }
                }
            }
            newOptions.yAxis.plotLines = plotLines;

            if (newMin <= 0)
                newOptions.yAxis.min = newMin;
           
        }
        newOptions.xAxis.events = {
            afterSetExtremes: function (event) {
                if (event.min > 0)
                    Event("User Zoomed In On Graph", "User zoomed in on graph", "User Zoomed In On Graph");
            }
        };
        return newOptions;
    }

    render() {
        let radios150s = !this.props.isAirConditioner || this.props.isTraining ?
            <span>
                <input type="radio" value="First150" name="viewType" />
                    &nbsp; First 150s &nbsp;&nbsp;
                <input type="radio" value="Last150" name="viewType" />
                    &nbsp; Last 150s &nbsp;&nbsp;
            </span>
            : null;

        let rawRadio = !this.props.isTraining ?
            <span>
                <input type="radio" value="RawData" name="viewType" />
                    &nbsp; Raw Data &nbsp;&nbsp;
            </span>
            : null;

        let radios = this.props.graphType === "ALERT" ?
            <div className="radio-container" onChange={e => this.changeRadio(e)} style={{ marginLeft: '80px' }}>
                <span>
                    <input type="radio" value="WholeRun" defaultChecked name="viewType" />
                    &nbsp; Whole Run &nbsp;&nbsp;
                    {radios150s}
                    {rawRadio}
                </span>
            </div>
            :null;

        return (
            <div>
                {radios}
                <Graph key={`${this.props.chartData.length}-${this.props.isAirConditioner}-${Math.random()}-${this.state.viewType}`} options={this.getOptions(this.props.chartData)} />
            </div>
        );
    }
}