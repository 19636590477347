/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

export var AlertTypeIdsEnum = {
    UnitOffline: "35"
};

export var TagIdsEnum = {
    AlertPartnerViaEmail: "78",
    JobCreated: "91",
    NewAlert: "94",
    QuestionAsked: "98",
    QuestionAnswered: "99",
    NewComment: "172"
};

export var ConversationTypeEnum = {
    AlertComment: 3,
    OpportunityComment: 2
};

export var AlertIndicatorSort = {
    None: 0,
    NewAlerts: 1,
    QuestionAnswered: 2,
    QuestionAsked: 3,
    NewComments: 4
};

export var ThermostatPocData = {
    apiKey: "j84mnRf4qEDu3ieMBZYCzwGpJQk2VtCq",
    userRefID: "1706367",
    locationId: "1499564",
    deviceId: "LCC-B82CA049D8DA",
    updateSP: {
        mode: "Heat",
        heatSetpoint: 90,
        coolSetpoint: 60,
        thermostatSetpointStatus: "TemporaryHold",
        autoChangeoverActive: true,
        nextPeriodTime: "18:00:00",
        heatCoolMode: "Heat"
    }
}

export var jsAsset = {
    assetId: 0,
    description: "",
    mac: "",
    make: "",
    mfgDate: "",
    model: "",
    name: "",
    nickname: "",
    parentAssetId: 0,
    path: [],
    properties: [],
    sensorFw: "",
    sensorMac: "",
    serialNumber: "",
    status: 0,
    type: "",
    classes: [],
    unappliedClasses: [],
    applyList: [],
    removeList: [],
    compressorRatedLoadAmps: "",
    compressorLockedRotorAmps: "",
    fanFullLoadAmps: "",
    fanLockedRotorAmps: "",
    furnaceMac: "",
    furnaceId: "",
    furnaceCurrentPropId: "",
    furnaceTempPropId: "",
    acCurrentPropId: "",
    acTempPropId: "",
    offHigh: "",
    hasAnalytics: false
};

export var jsHouse = {
    orgId: "",
    parentId: "",
    houseAssetId: "",
    nickname: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    latitude: "",
    longitude: "",
    location: "",
    locationId :""
};

export var jsDevice = {
    deviceId: "",
    parentId: "",
    nickname: "",
    mac: "",
    subMac: "",
    deviceType: "",
    status: "",
    portalStatus: "",
    orgName: "",
    sensor1: "",
    sensor2: "",
    mapsTo1: "",
    mapsTo2: "",
    createdOn: "",
    lastUpdated: "",
    gatewayFw: "",
    wifiRSSI: 0,
    wifiRSSI_dBm: 0,
    wifiRSSI_Scale: 0,
    wifiRSSI_Timestamp: "",
    dropouts: "",
    subgRSSI: 0,
    subgRSSI_dBm: 0,
    subgRSSI_Scale: 0,
    subgRSSI_Timestamp: "",
    subgChan: "",
    sensorMAC: "",
    sensorFw: "",
    configuration: "",
    batteryVoltage: "",
    batteryVoltage_Timestamp: "",
    subBatteryVoltage: "",
    deviceTemperature: "",
    deviceTemperature_Timestamp: "",
    current: "",
    current_Timestamp: ""

};

export var alertCustomer = {
    parentOrganizationId: null,
    organizationId: null,
    userId: null,
    houseAssetId: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    name: null,
    address: "",
    city: "",
    state: "",
    zip: ""
};

export var jsPoint = {
    orgId: "",
    parentId: "",
    houseAssetId: "",
    color: "",
    name: "",
    address: "",
    phone: "",
    email: "",
    latitude: null,
    longitude: null,
    house: null,
    openActiveAlerts: 0,
    openInactiveAlerts: 0
};

export var jsOpportunity = {
    caseId: "",
    number: "",
    type: "",
    priority: 0,
    status: "",
    sourceId: 0,
    subject: "",
    impact: undefined,
    comment: "",
    assignedToId: "",
    assignedToName: "",
    createdOnStr: "",
    assignedOnStr: "",
    closedOnStr: "",
    scheduledForStr: "",
    scheduledTimeStr: "",
    scheduledForTimeStr: "",
    closedTimeStr: "",
    details: [],
    isFalsePositive: null,
    alertId: "",
    assetId: "",
    assetType: ""
};

export var jsUpdateOpportunity = {
    caseId: 0,
    subject: "",
    //sourceId: "",
    priority: 0,
    comment: ""
}

export var jsComponent = {
    caseId: 0,
    componentId: -1,
    oem: "",
    other: "None"
}

export var jsAppointment = {
    caseId: 0,
    appointmentDate: null,
    verificationId: 0,
    useCaseId: 0,
    timeToRepair: 0,
    jobValue: "",
    notes: "",
    closed: null,
    componentReplaced: []
}

export var jsConversation = {
    conversationId: null,
    conversationtype: 3,
    genesisId: "",
    message: "",
    private: false
}

export var jsAlert = {
    alertId: "",
    name: "",
    priority: 0,
    message: "",
    recommendation: "",
    comments: "",
    startTime: "",
    startTimeFormatted: "",
    duration: "",
    endTime: "",
    assetClassIds: [],
    drill: "",
    isFalsePositive: null,
    isVerified: null,
    isOpen: null,
    isClosed: null,
    isMuted: null,
    closedOn: "",
    assetId: "",
    organization: "",
    organizationName: "",
    isActive: null,
    isAssetActive: null,
    typeId: ""
};

export var jsGraph = {
    deviceId: "",
    startTimeStr: "",
    endTimeStr: "",
    startTimeDailyStr: "",
    endTimeDailyStr: "",
    maxPointsInRunTime: 0,
    maxPointsInDailyTime: 0,
    dataArray1: [],
    dataArray2: [],
    currentData1: [],
    currentData2: [],
    currentArray1: [],
    currentArray2: [],
    yDataMin1: 0,
    yDataMax1: 0,
    yDataMin2: 0,
    yDataMax2: 0,
    alertTimeStr: "",
    alertStartTime: null,
    alertEndTime: null,
    dailyStartTime: null,
    dailyEndTime: null,
    runGraphData: null,
    dailyGraphData: null
};

export var jsACGraph = {
    deviceIdAC: "",
    deviceIdFurnace: "",
    furnaceMAC: "",
    startTimeStr: "",
    endTimeStr: "",
    startTimeDailyStr: "",
    endTimeDailyStr: "",
    maxPointsInRunTime: 0,
    maxPointsInDailyTime: 0,
    dataArray1: [],
    dataArray2: [],
    currentData1: [],
    currentData2: [],
    currentArray1: [],
    currentArray2: [],
    yDataMin1: 0,
    yDataMax1: 0,
    yDataMin2: 0,
    yDataMax2: 0,
    tempMax: 0,
    rla: 0,
    lra: 0,
    alertTimeStr: "",
    alertStartTime: null,
    alertEndTime: null,
    dailyStartTime: null,
    dailyEndTime: null,
    runGraphData: null,
    dailyGraphData: null
};

export var jsAssetTimelineGraph = {
    deviceId: "",
    startTimeStr: "",
    endTimeStr: "",
    maxPoints: 0,
    dataArray: [],
    currentData: [],
    currentArray: [],
    yDataMin1: 0,
    yDataMax1: 0,
    timeStr: "",
    startTime: null,
    endTime: null,
    dailyGraphData: null
}

export var jsACAssetTimelineGraph = {
    deviceIdAC: "",
    deviceIdFurnace: "",
    peerType: "Furnace",
    startTimeStr: "",
    endTimeStr: "",
    maxPoints: 0,
    dataArray: [],
    currentData: [],
    currentArray: [],
    yDataMin1: 0,
    yDataMax1: 0,
    tempMax: 0,
    rla: 0,
    lra: 0,
    timeStr: "",
    startTime: null,
    endTime: null,
    dailyGraphData: null
}

export var jsAssetsGraphData = {
    deviceId: "",
    startTime: null,
    startTimeStr: "",
    endTime: null,
    endTimeStr: "",
    timeStr: "",
    offHigh: 0.3,
    maxPointsInRunTime: 0,
    properties: [],
    unsetProperties: [],
    currentData: [],
    currentArray: [],
    dataArray: [],
    equipmentRuns: [],
    runNamesList: [],
    timeSeriesDataSources: [],
    graphData: []
};

export var jsACAssetsGraphData = {
    deviceIdAC: "",
    deviceIdFurnace: "",
    peerType: "Furnace",
    furnaceMAC: "",
    startTime: null,
    startTimeStr: "",
    endTime: null,
    endTimeStr: "",
    timeStr: "",
    offHigh: 0.3,
    rla: 0.0,
    lra: 0.0,
    maxPointsInRunTime: 0,
    propertiesAC: [],
    unsetPropertiesAC: [],
    propertiesFurnace: [],
    unsetPropertiesFurnace: [],
    currentData: [],
    currentArray: [],
    dataArray: [],
    equipmentRuns: [],
    runNamesList: [],
    timeSeriesDataSources: []
};

export var jsGraphData = {
    count: null,
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
    dataArray: [],
    dataSource: [
        {
            timeStamp: "",
            value: "",
            index: -1
        }],
    propertiesForAnalytics: {},
    graphAnnotations: [],
    graphArrowAnnotations: [],
    timeString: ""
};

export var jsACGraphData = {
    count: null,
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
    dataArray: [],
    dataSource: [
        {
            timeStamp: "",
            value: "",
            furnaceValue: "",
            rla: "",
            lra: "",
            indoorTemp: "",
            outdoorTemp: "",
            index: -1
        }],
    propertiesForAnalytics: {},
    graphAnnotations: [],
    graphArrowAnnotations: [],
    timeString: ""
};

export var jsSource = {
    count: 0,
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
    dataArray: [],
    dataSource: [
        {
            timeStamp: "",
            value: "",
            index: -1
        }],
    propertiesForAnalytics: {},
    graphAnnotations: [],
    graphArrowAnnotations: [],
    timeString: ""
};

export var jsACSource = {
    count: 0,
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
    dataArray: [],
    dataSource: [
        {
            timeStamp: "",
            value: "",
            furnaceValue: "",
            rla: "",
            lra: "",
            indoorTemp: "",
            outdoorTemp: "",
            index: -1
        }],
    propertiesForAnalytics: {},
    graphAnnotations: [],
    graphArrowAnnotations: [],
    timeString: ""
};

export var jsDetails = {
    caseId: 0,
    number: "",
    type: "",
    priority: 0,
    status: "",
    impact: "",
    impactEstimate: "",
    comment: "",
    assignedToId: "",
    scheduledFor: "",
    isClosed: false,
    scheduledOn: "",
    closedOn: "",
    isFalsePositive: null,
    organizationId: 0,
    houseAssetId: "",
    customerOrgId: "",
    customerFirstName: "",
    customeLastName: ""
};

export var jsTechnician = {
    userId: "",
    firstName: null,
    lastName: null,
    email: null,
    isActive: null,
    lastLogin: null,
    roleList: null,
    roleListId: [],
    roleListIdStr: null
};

export var jsCustomer = {
    parentOrganizationId: null,
    organizationId: null,
    parentAccountId: null,
    accountId: null,
    userId: null,
    houseAssetId: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    name: null,
    address: "",
    city: "",
    state: "",
    zip: ""
};

export var jsPartner = {
    name: "",
    company: "",
    description: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    logo: null,
    logoId: null,
    icon: null,
    iconId: null,
    logoData: null,
    iconData: null,
    notificationEmailAddresses: null,
    users: [
        {
            firstName: null,
            lastName: null,
            email: null,
            isActive: null,
            lastLogin: null,
            roleList: null
        }
    ],
    isBillable: "",
    contractType: "",
    firstBillingDate: "",
    complementaryUnits: 0
};

export var jsHomeowner = {
    firstName: "",
    lastName: "",
    company: "",
    description: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    locationName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    parentOrganizationId: null,
    organizationId: null,
    userId: null,
    houseAssetId: null
};

export var jsAccount = {
    parentAccountId: null,
    accountId: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    description: null,
    company: null,
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    familyMembers: [
        {
            name: null,
            email: null,
            userId: null
        }
    ],
    inviteEmail: null,
    customerRecordId :""
};

export var jsCurrentAccount = {
    firstName: null,
    lastName: null,
    email: null,
    picture: null
};

export var jsAssetDeletionLog = {
    userEmail: "",
    assetList: [
        {
            assetId: null,
            assetName: ""
        }
    ]
};

export var jsAvgCurrents = {
    avgMainCurrent: [],
    avgInducerCurrent: [],
    avgBlowerCurrent: []
};

export var jsScheduleDetails = {
    scheduledDate: "",
    technician: "",
    jobvalue: "",
    jobNotes: "",
    jobStatus: "",
    lwJobID : "",
    stID :""
};

export var roles = {
    admin: 'ADMIN',
    customerSupport: 'CUSTOMER SUPPORT',
    operations: 'OPERATIONS',
    partnerAdmin: 'PARTNER ADMIN',
    supportAdmin: 'SUPPORT ADMIN',
    qa: 'QA',
    scheduler: 'SCHEDULER',
    superAdmin: 'SUPER ADMIN',
    technician: 'TECHNICIAN'
}

export var allRoleList = [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.supportAdmin, roles.technician, roles.scheduler]

export var alertSearchStartDate = "2021-04-01";



export var jsSession = {
    id: 0,
    userId: 0,
    dailyId: 0,
    startDateTimeStr: "",
    startDateTime: null,
    endDateTimeStr: "",
    endDateTime: null,
    triagedAlerts: []
};

export var furnacePropertyTypes = [
        {
            "name": "id",
            "type": "STATIC"
        },
        {
            "name": "ignitionLow",
            "type": "FLOAT"
        },
        {
            "name": "ignitionHigh",
            "type": "FLOAT"
        },
        {
            "name": "afueRating",
            "type": "STATIC"
        },
        {
            "name": "supportsIgnitionFromBlowerOn",
            "type": "BOOL"
        },
        {
            "name": "fanOnlyMode",
            "type": "STATIC"
        },
        {
            "name": "fanOnlyHigh",
            "type": "FLOAT"
        },
        {
            "name": "fanOnlyLow",
            "type": "FLOAT"
        },
        {
            "name": "fanOnlyOnHigh",
            "type": "STATIC"
        },
        {
            "name": "fanOnlyOnLow",
            "type": "STATIC"
        },
        {
            "name": "offHigh",
            "type": "STATIC"
        },
        {
            "name": "delayHigh",
            "type": "FLOAT"
        },
        {
            "name": "delayLow",
            "type": "FLOAT"
        },
        {
            "name": "blowerOnHigh",
            "type": "FLOAT"
        },
        {
            "name": "blowerOnLow",
            "type": "FLOAT"
        },
        {
            "name": "blowerOffDelay",
            "type": "INTEGER"
        },
        {
            "name": "shutdownHigh",
            "type": "FLOAT"
        },
        {
            "name": "shutdownLow",
            "type": "FLOAT"
        },
        {
            "name": "blowerDelayDuration",
            "type": "INTEGER"
        },
        {
            "name": "postPurgePeriod",
            "type": "INTEGER"
        },
        {
            "name": "inductionHigh",
            "type": "FLOAT"
        },
        {
            "name": "inductionLow",
            "type": "FLOAT"
        },
        {
            "name": "inductionOnAmps",
            "type": "FLOAT"
        },
        {
            "name": "inductionOffAmpsMin",
            "type": "FLOAT"
        },
        {
            "name": "inductionOffAmpsMax",
            "type": "FLOAT"
        },
        {
            "name": "instantInduction",
            "type": "BOOL"
        },
        {
            "name": "gasValveOffLow",
            "type": "FLOAT"
        },
        {
            "name": "gasValveOffHigh",
            "type": "FLOAT"
        },
        {
            "name": "prepurgeHigh",
            "type": "FLOAT"
        },
        {
            "name": "prepurgeLow",
            "type": "FLOAT"
        },
        {
            "name": "ignitionDuration",
            "type": "INTEGER"
        },
        {
            "name": "ignitionRetries",
            "type": "INTEGER"
        },
        {
            "name": "igniterOnAmps",
            "type": "FLOAT"
        },
        {
            "name": "blowerTypeId",
            "type": "STATIC"
        },
        {
            "name": "blowerTypeName",
            "type": "SELECT"
        },
        {
            "name": "blowerStageId",
            "type": "STATIC"
        },
        {
            "name": "blowerStageName",
            "type": "SELECT"
        },
        {
            "name": "igniterTypeId",
            "type": "STATIC"
        },
        {
            "name": "igniterTypeName",
            "type": "SELECT"
        }
    ];

export var jsAnalyzeIgnition = {
    delayHigh: "",
    delayLow: "",
    ignitionLow: "",
    ignitionHigh: "",
    igniterONAmps: ""
};
export var jsAnalyzeShutdown = {
    gasValveOffHigh: "",
    gasValveOffLow: "",
    inductionOffAmps: "",
    shutdownHigh: "",
    shutdownLow: ""
};

export var assetType = {
    Furnace: 1,
    AirConditioner: 2,
    HeatPump: 3,
    AirHandler: 4
}
