/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/partners";
class PartnerService {

    //set isActive = false for VersionCheck  
    async get(obj) { //organization, isPartnerAdmin, isActive) {
        const response = await http.get(`${controller}?organization=${obj.organization}&isPartnerAdmin=${obj.isPartnerAdmin}&isActive=${obj.isActive}`); 
        const data = await response.data;
        return data;
    }

    async addnewpartner(partner) {
        const response = await http.post(`${controller}/addnewpartner`, partner);
        const data = await response.data;
        return data;
    }

    async refreshdashboardsection(section, currInfo) {
        const response = await http.post(`${controller}/refreshdashboardsection?section=${section}`, currInfo);
        const data = await response.data;
        return data;
    }

    async getonboardinginfo(orgId, isAC = true) {
        const response = await http.get(`${controller}/getonboardinginfo?orgId=${orgId}&isAC=${isAC}`);
        const data = await response.data;
        return data;
    }

    async getassettraininginfo(orgId, isAC = true) {
        const response = await http.get(`${controller}/getassettraininginfo?orgId=${orgId}&isAC=${isAC}`);
        const data = await response.data;
        return data;
    }

    async updateservicetier(partnerId,tierId) {
        const response = await http.post(`${controller}/updateservicetier?partnerId=${partnerId}&tierId=${tierId}`);
        const data = await response.data;
        return data;
    }

    async updatemuteuntilalert(partnerId, muteUntilDate) {
        const response = await http.post(`${controller}/updatemuteuntilalert?partnerId=${partnerId}&muteUntilDate=${muteUntilDate}`);
        const data = await response.data;
        return data;
    }

    async adddeletedinfrastructure(partnerId, partnerCompanyName) {
        const response = await http.post(`${controller}/adddeletedinfrastructure?partnerId=${partnerId}&partnerCompanyName=${partnerCompanyName}`);
        const data = await response.data;
        return data;
    }

    async getreleasenotes() {
        const response = await http.get(`/partnerlist/getreleasenotes`);
        const data = await response.data;
        return data;
    }

    async addreleasenotes(notes) {
        const response = await http.post(`/partnerlist/addreleasenotes`, notes);
        const data = await response.data;
        return data;
    }
}

export default new PartnerService();
