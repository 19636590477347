/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import HomeService from "../../services/home.service";
import AssetService from '../../services/asset.service';
import SelectHome from "./SelectHome";
import AddHouseModal from "./AddHouseModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

import memoize from "memoize-one";

import { jsHouse } from "../../componentObjects";
import { PageView, Event, ModalView } from "../GoogleAnalytics";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class Home extends Component {
    static displayName = Home.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.populateHomeData = this.populateHomeData.bind(this);
        //this.populateHomeDataNew = this.populateHomeDataNew.bind(this); 
        
        this.addHome = this.addHome.bind(this);
        this.updateHome = this.updateHome.bind(this);
        this.deleteHome = this.deleteHome.bind(this);

        this.updateHomeListInParent = this.updateHomeListInParent.bind(this);
        this.removeFromHomeList = this.removeFromHomeList.bind(this);
        this.addToHomeList = this.addToHomeList.bind(this);

        this.showAddHomeModal = this.showAddHomeModal.bind(this);
        this.openAddHomeModal = this.openAddHomeModal.bind(this);

        this.onHomeSelect = this.onHomeSelect.bind(this);
        this.onChangeNickName = this.onChangeNickName.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeZip = this.onChangeZip.bind(this);
        this.onChangeLatitude = this.onChangeLatitude.bind(this);
        this.onChangeLongitude = this.onChangeLongitude.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);

        this.onNickNameChange = this.onNickNameChange.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.onZipChange = this.onZipChange.bind(this);        
        this.onLatitudeChange = this.onLatitudeChange.bind(this);
        this.onLongitudeChange = this.onLongitudeChange.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onLocationRecordIdChange = this.onLocationRecordIdChange.bind(this);
        this.onLocationRecordIdAddHomeChange = this.onLocationRecordIdAddHomeChange.bind(this);

        this.openUpdateHomeModal = this.openUpdateHomeModal.bind(this);
        this.showUpdateHomeModal = this.showUpdateHomeModal.bind(this);
        this.openDeleteHomeModal = this.openDeleteHomeModal.bind(this);
        this.showDeleteHomeModal = this.showDeleteHomeModal.bind(this);
        
        this.state = {
            homeList: [],
            loading: false,
            homeLoading: false,
            currentHome: jsHouse,
            newHome: jsHouse,
            showAddHome: false,
            statusMessage: null,
            showStatusMessage: null,
            showDeleteConfirmation: false,
            showUpdateConfirmation: false,
            modalMessage: "",
            modalTitle: "",
            selectedHomeIndex: -1,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
        };             
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading) {    //this.state.loading === false)
            this.populateHomeData();            
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //memHomeData = memoize(this.populateHomeDataNew);
    
    onHomeSelect(e) {
        this.setState({
            currentHome: this.state.homeList[e.target.value], selectedHomeIndex: parseInt(e.target.value, 10)
        });
    }        

    showAddHomeModal = e => {
        this.setState({
            showAddHome: !this.state.showAddHome
        }, () => {
                if (this.state.showAddHome)
                    ModalView("addHomeModal");
        });
        window.scrollTo(0, 0);
    }

    openAddHomeModal() {
        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                orgId: this.props.currentCustomerId
            }
        }));

        this.showAddHomeModal(null);
    }

    updateHomeListInParent(currentHome) {
        for (let i = 0; i < this.state.homeList.length; i++) {

            if (this.state.homeList[i].houseAssetId === currentHome.houseAssetId) {
                // our location is i

                let homes = [...this.state.homeList];

                let home = { ...homes[i] };

                home = currentHome;

                homes[i] = home;

                this.setState({ homeList: homes });
            }
        }
    }

    removeFromHomeList(currentHome) {

        for (let i = 0; i < this.state.homeList.length; i++) {

            if (this.state.homeList[i].houseAssetId === currentHome.houseAssetId) {
                var array = [...this.state.homeList];

                if (i !== -1) {
                    array.splice(i, 1);
                    this.setState({ homeList: array });
                }

            }
        }
    }

    addToHomeList(newHome) {
        var homes = [...this.state.homeList];

        homes.push(newHome);

        this.setState({ homeList: homes });
    }

    addHome() {
        this.setState({ loading: true });
        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                orgId: this.props.currentCustomerId,
                parentId: this.props.currentPartnerId
            }
        }), () => {
            HomeService.add(this.state.newHome)
                .then(response => {
                    let message = "";

                    if (response.data !== undefined && response.data.houseAssetId !== "") {
                        message = "Successfully added new home!";
                        Event("Add Home Clicked", "User clicked add home button", "Add Customer Home Clicked");

                        this.addToHomeList(this.state.newHome);

                        let index = this.state.homeList.indexOf(this.state.newHome);

                        this.setState({
                            showAddHome: !this.state.showAddHome, statusMessage: message, loading: false,
                            newHome: jsHouse, currentHome: response.data, selectedHomeIndex: parseInt(index, 10)
                        });

                    }
                    else {
                        message = "Failed to add new home!";

                        this.setState({
                            showAddHome: !this.state.showAddHome, statusMessage: message,
                            newHome: jsHouse, currentHome: response.data, loading: false
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ loading: false });
                });
        });
    }    

    updateHome() {
        this.setState({ loading: true });
        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                orgId: this.props.currentCustomerId,
                parentId: this.props.currentPartnerId
            }
        }), () => {
            HomeService.update(
                this.state.currentHome
            ).then(response => {
                let message = "";

                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    message = "Successfully updated home!";
                    Event("Update Home Clicked", "User clicked update home button", "Update Customer Home Clicked");

                    if (this._isMounted)
                        this.setState({
                            statusMessage: message,
                            showUpdateConfirmation: false,
                            loading: false
                        }, async () => {
                            await AssetService.refreshhomeassets(this.props.currentPartnerId)
                                .then(response2 => {
                                    if (response2.status === 200) {
                                        console.log("Successfully updated home assets cache")
                                    }
                                    else {
                                        console.log("Failed to refresh home assets cache")
                                    }

                                });
                        });

                    // update the master list in app.js.
                    this.updateHomeListInParent(this.state.currentHome);
                }
                else {
                    message = "Failed to update home!";

                    if (this._isMounted)
                        this.setState({ statusMessage: message, showUpdateConfirmation: false, loading: false });
                }

            }).catch(e => {
                console.log(e);
                this.setState({ loading: false });
            });
        });
    }

    deleteHome() {
        this.setState({ loading: true });
        HomeService.delete(this.state.currentHome.houseAssetId, this.props.currentCustomerId, this.props.currentPartnerId)
            .then(response => {
                let message = "";

                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    message = "Successfully deleted home!";
                    Event("Delete Home Clicked", "User clicked delete home button", "Delete Customer Home Clicked");

                    if (this._isMounted)
                        this.setState({ statusMessage: message, showDeleteConfirmation: false, loading: true });

                    this.removeFromHomeList(this.state.currentHome);
                }
                else {
                    message = "Failed to delete home!";

                    if (this._isMounted)
                        this.setState({ statusMessage: message, showDeleteConfirmation: false, loading: true });
                }

            }).then(this.populateHomeData)
            .catch(e => {
                console.log(e);
                this.setState({ loading: true });
            });
    }

    showDeleteHomeModal() {
        this.setState({
            showDeleteConfirmation: !this.state.showDeleteConfirmation
        }, () => {
                if (this.state.showDeleteConfirmation)
                    ModalView("deleteHomeConfirm");
        });
        window.scrollTo(0, 0);
    }

    openDeleteHomeModal() {
        this.setState({ modalMessage: "Are you sure you wish to delete this Home?", modalTitle: "Delete Home" }, () => {
            this.showDeleteHomeModal();
        });
    }

    showUpdateHomeModal() {
        this.setState({
            showUpdateConfirmation: !this.state.showUpdateConfirmation
        }, () => {
                if (this.state.showUpdateConfirmation)
                    ModalView("updateHomeConfirm");
        });
        window.scrollTo(0, 0);
    }

    openUpdateHomeModal() {
        this.setState({ modalMessage: "Are you sure you wish to update this Home?", modalTitle: "Update Home" }, () => {
            this.showUpdateHomeModal();
        });
    }

    /**
     * begin input event handlers
     */
    onChangeNickName(e) {
        const nickName = e.target.value;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                nickname: nickName
            }
        }));
    }

    onChangeAddress(e) {
        const address = e.target.value;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                address: address
            }
        }));
    }

    onChangeCity(e) {
        const city = e.target.value;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                city: city
            }
        }));
    }

    onChangeState(e) {
        const state = e.target.value;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                state: state
            }
        }));
    }

    onChangeZip(e) {
        const zip = e.target.value;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                zip: zip
            }
        }));
    }

    /* LOCATION EVENTS */ 
    onChangeLatitude(e) {
        const latitude = e.target.value;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                latitude: latitude
            }
        }));
        this.onChangeLocation();
    }

    onChangeLongitude(e) {
        const longitude = e.target.value;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                longitude: longitude
            }
        }));
        this.onChangeLocation();
    }

    onChangeLocation() {
        const location = this.state.currentHome.latitude + "," + this.state.currentHome.longitude;

        this.setState(prevState => ({
            currentHome: {
                ...prevState.currentHome,
                location: location 
            }
        }));
    }

    onLocationRecordIdChange(e) {
        const regex = /^\d+$/;
        let locationRecordId = e.target.value;
        
        if ((regex.test(locationRecordId) || locationRecordId === "") && locationRecordId.length <= 7) {
            this.setState(prevState => ({
                currentHome: {
                    ...prevState.currentHome,
                    locationId: locationRecordId
                }
            }));
        }
    }
    /*end*/


    onNickNameChange(e) {
        var nickName = e.target.value;
        nickName = nickName.replace("'", "&#39;");

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                nickname: nickName
            }
        }));
    }

    onAddressChange(e) {
        const address = e.target.value;

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                address: address
            }
        }));
    }
    onCityChange(e) {
        const city = e.target.value;

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                city: city
            }
        }));
    }
    onStateChange(e) {
        const state = e.target.value;

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                state: state
            }
        }));
    }
    onZipChange(e) {
        const zip = e.target.value;

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                zip: zip
            }
        }));
    }

    /* LOCATION EVENTS 2 */
    onLatitudeChange(e) {
        const latitude = e.target.value;

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                latitude: latitude 
            }
        }));
        this.onLocationChange();
    }

    onLongitudeChange(e) {
        const longitude = e.target.value;

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                longitude: longitude 
            }
        }));
        this.onLocationChange();
    }

    onLocationChange() {
        const location = this.state.newHome.latitude + "," + this.state.newHome.longitude;

        this.setState(prevState => ({
            newHome: {
                ...prevState.newHome,
                location: location 
            }
        }));
    }

    onLocationRecordIdAddHomeChange(e) {
        const regex = /^[0-9\b]+$/;
        let locationRecordId = e.target.value.replace(/\+|-/gi, "");

        if ((regex.test(locationRecordId) || locationRecordId === "") && locationRecordId.length <= 7) {
            this.setState(prevState => ({
                newHome: {
                    ...prevState.newHome,
                    locationId: locationRecordId
                }
            }));
        }
    }

    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            var name = split[0].replace("?", "'");

            const indexOfSlash = name.indexOf(' / ');
            if (indexOfSlash !== -1) {
                name = name.substring(indexOfSlash + 3);
            }
            return name;
        }
        else {
            return "";
        }
    }

    /**
     * end input event handlers
     */

    renderHomes(homeList, currentId) {
        let LocationId = (this.props.hasServiceTitan && this.props.hasBilling ) ?
            <div>
                <label>Service Titan Location Id</label>
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="add-latitude"
                            value={this.state.currentHome?.locationId}
                            onChange={this.onLocationRecordIdChange}
                            placeholder="Service Titan Location Id"
                        />
                    </div>
                </div>
            </div>
            : null 
        if (homeList.length){
            return (
                <div>
                    <div style={{ display: 'none' }}>{currentId}</div>

                    <div className="row no-gutters">      
                        <div className="white-container col-sm-12 col-md-9 col-lg-6">
                            <SelectHome homes={homeList} onChange={this.onHomeSelect} selectedIndex={this.state.selectedHomeIndex} />
                        </div>
                        <div className="white-container col-sm-12 col-md-9 col-lg-6">

                            <form className="input-container">  
                                
                            <div>
                                <label>Nickname</label>
                                <input 
                                    type="text"
                                    className="form-control"
                                        id="nick-name"
                                        value={this.getPrettyName(this.state.currentHome.nickname)}
                                    onChange={this.onChangeNickName}
                                />
                            </div>
                            <div>
                                <label>Address</label>
                                <input 
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    value={this.state.currentHome.address}
                                    onChange={this.onChangeAddress}
                                />
                            </div>
                            <div>
                                <label>City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    value={this.state.currentHome.city}
                                    onChange={this.onChangeCity}
                                />
                            </div>
                            <div>
                                <label>State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="state"
                                    value={this.state.currentHome.state}
                                    onChange={this.onChangeState}
                                />
                            </div>
                            <div>
                                <label>Zip</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="zip"
                                    value={this.state.currentHome.zip}
                                    onChange={this.onChangeZip}
                                />
                            </div>                       
                            <div style={{ marginBottom: '15px' }}>
                                <label>Location</label>
                                <div className="row">
                                    <div className="col">
                                        <input 
                                            type="text"
                                            className="form-control"
                                            id="latitude"
                                            value={this.state.currentHome.latitude}
                                            onChange={this.onChangeLatitude}
                                            placeholder="Latitude"
                                        />
                                    </div>
                                    <div className="col">
                                        <input 
                                            type="text"
                                            className="form-control"
                                            id="longitude"
                                            value={this.state.currentHome.longitude}
                                            onChange={this.onChangeLongitude}
                                            placeholder="Longitude"
                                        />
                                    </div>
                                </div>
                            </div>
                                {LocationId}
                            </form>
                        </div>

                    </div>
                    <p className="status-msg">{this.state.statusMessage}</p>
                    {(!this.state.isCustomerSupport) && (
                        <div className="table-container">
                            <button
                                type="button"
                                className="secondary-btn btn-small"
                                onClick={this.openAddHomeModal}
                            >
                                Add Home
                            </button>

                            <button
                                type="button"
                                className="secondary-btn btn-small"
                                onClick={this.openUpdateHomeModal}
                            >
                                Update Home
                            </button>

                            {/* TODO uncomment when ready to enable delete buttons - NW
                            <button
                                type="button"
                                className="secondary-btn btn-small"
                                onClick={this.openDeleteHomeModal}
                            >
                                Delete Home
                            </button>
                            */}


                        </div>)}

                    <AddHouseModal newHome={this.state.newHome} onClick={this.showAddHomeModal} show={this.state.showAddHome} onSave={this.addHome} onNickNameChange={this.onNickNameChange}
                        onAddressChange={this.onAddressChange} onCityChange={this.onCityChange} onStateChange={this.onStateChange} onZipChange={this.onZipChange} onLatitudeChange={this.onLatitudeChange} onLongitudeChange={this.onLongitudeChange} onLocationChange={this.onLocationChange}
                        onLocationRecordIdAddHomeChange={this.onLocationRecordIdAddHomeChange} hasBilling={this.props.hasBilling} hasServiceTitan={this.props.hasServiceTitan} >

                    </AddHouseModal>

                    <ConfirmationModal show={this.state.showDeleteConfirmation} onYes={this.deleteHome} onClick={this.showDeleteHomeModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>

                    <ConfirmationModal show={this.state.showUpdateConfirmation} onYes={this.updateHome} onClick={this.showUpdateHomeModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>

                </div>
            );
        }
        else if (this.props.currentCustomerId === -1) {
            return (
                <div>
                    <p className="status-msg">No customer home information to display.</p>
                </div>
            );
        }
        else{
            return (
                <div style={{ marginLeft: '10px' }}>
                    <div><br />No Homes located!</div>
                    <br/>
                    <button
                        type="button"
                        className="primary-btn btn-small"
                        onClick={this.openAddHomeModal}
                    >
                        Add Home
                        </button>

                    <AddHouseModal newHome={this.state.newHome} onClick={this.showAddHomeModal} show={this.state.showAddHome} onSave={this.addHome} onNickNameChange={this.onNickNameChange}
                        onAddressChange={this.onAddressChange} onCityChange={this.onCityChange} onStateChange={this.onStateChange} onZipChange={this.onZipChange} onLatitudeChange={this.onLatitudeChange} onLongitudeChange={this.onLongitudeChange} onLocationChange={this.onLocationChange} >

                    </AddHouseModal>
                </div>
            );
        }
    }

    render() {
        const { classes } = this.props;

        let contents = this.state.loading
            ? <div className={["loading", classes.loader].join(' ')} style = {{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderHomes(this.state.homeList, this.props.currentCustomerId);

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h3 className="pageTitle" id="tabelLabel" >Home</h3>                
                    {contents}
                </div>
            </div>
        );
    }

    populateHomeData() {
        if (!this.props.isLoading) {
            this.setState({ loading: true }, async () => {
                await HomeService.get(this.props.currentCustomerId).then(homeResponse => {
                    if (this._isMounted)
                        this.setState({ homeList: homeResponse.data, currentHome: homeResponse.data[0], selectedHomeIndex: 0 }, () => {
                            this.setState({ loading: false });
                        });
                }).catch(e => {
                    console.log(e);
                });
            })
        }
    }

    /*async populateHomeDataNew(propId) {
        await HomeService.get(propId).then(homeResponse => {
            if (this._isMounted)
                this.setState({ homeList: homeResponse.data, currentHome: homeResponse.data[0], selectedHomeIndex: 0 }, () => {
                    this.setState({ loading: false });
                });
        }).catch(e => {
            console.log(e);
        });
    }*/
}

export default withStyles(styles)(Home);
