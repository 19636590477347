/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/mailjet";
class MailjetService {

    async getmessages(starttime, endtime, limit, offset, contact="") { 
        const response = await http.get(`${controller}/messages?startTime=${starttime}&endTime=${endtime}&limit=${limit}&offset=${offset}&contact=${contact}`);
        const data = await response.data;
        return data;
    }

    async getallcontactmessages(contact = "") {
        const response = await http.get(`/mailjet/allcontactmessages?contact=${contact}`);
        const data = await response.data;
        return data;
    }

    async getmessagecount(starttime, endtime, contact) {
        const response = await http.get(`${controller}/messagecount?startTime=${starttime}&endTime=${endtime}&contact=${contact}`);
        const data = await response.data;
        return data;
    }

    async getsenders() {
        const response = await http.get(`${controller}/senders`);
        const data = await response.data;
        return data;
    }

    async getcontact(email) {
        const encodedEmail = encodeURIComponent(email);
        const response = await http.get(`/mailjet/contact?email=${encodedEmail}`);
        const data = await response.data;
        return data;
    }

    async getmessagehistory(messageId) {
        const response = await http.get(`${controller}/messagehistory?messageId=${messageId}`);
        const data = await response.data;
        return data;
    }
}

export default new MailjetService();