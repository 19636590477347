/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import HomeService from "../../services/home.service";
import AssetService from "../../services/asset.service";
import DeviceService from "../../services/device.service";
import AlertService from "../../services/alerts.service";

import ProvisioningService from "../../services/provisioning.service";

import SelectHome from "../Home/SelectHome";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { Battery } from '@pxblue/react-progress-icons';
import '../../custom.css';

import memoize from "memoize-one";

import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";
import { jsAsset, jsDevice, jsHouse } from "../../componentObjects";
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import threadService from "../../services/thread.service";
import installService from "../../services/install.service";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});
//const timeout = 300000; //300,000 milliseconds = 5 minutes
const DEVICE_STATUS_THREAD_NAME = "Device Status_LPX";
const STATUS_PROP_NAME = "Status";

var contents = <div className="loading" style={{ display: "block" }}>
    <div className="loading-wrapper">
        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
    </div></div>;

function collect(props) {
    return props;
}

function getPrettyName(name) {
    if (name !== null && name !== undefined) {

        var split = name.split("__");
        return split[0];
    }
    else {
        return "";
    }
}

class Devices extends Component {
    static displayName = Devices.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onHomeSelect = this.onHomeSelect.bind(this);
        this.getDevices = this.getDevices.bind(this);
        this.onAssetClick = this.onAssetClick.bind(this);
        this.onContextClick = this.onContextClick.bind(this);

        this.openConfirmationModal = this.openConfirmationModal.bind(this);        
        this.showConfirmationModal = this.showConfirmationModal.bind(this);
        this.updateSensorMap = this.updateSensorMap.bind(this);
        this.onHVACSystemSelect = this.onHVACSystemSelect.bind(this);

        this.openTTConfirmModal = this.openTTConfirmModal.bind(this);
        this.showTTConfirmModal = this.showTTConfirmModal.bind(this);
        this.onTTConfirm = this.onTTConfirm.bind(this);
        this.refreshStatus = this.refreshStatus.bind(this);
        this.refreshAllStatus = this.refreshAllStatus.bind(this);

        this.populateData = this.populateData.bind(this);
        this.populate = this.populate.bind(this);
        
        this.state = {
            deviceList: [],
            homeList: [],
            hvacList: [],
            assetList: [],

            currentHVACSystem: jsHouse,
            currentHome: jsHouse,
            currentAsset: jsAsset,
            currentDevice: jsDevice,
            currentInstall: {},
            currentInstallTechStr: "",   

            loading: false,
            newCustomerLoading: false,
            newHouseLoading: false,
            assetInfoLoading: false,
            configLoading: false,                 
            isSelected: -1,
            showConfirmation: false,
            showTTConfirm: false,
            modalMessage: "",
            modalTitle: "",
            statusMessage: null,
            showStatusMessage: null,
            tooltipSensorOpen: false,
            setTooltipSensorOpen: true,
            tooltipWifiOpen: false,
            deviceConfiguration: "",
            devTTUrl: "",
            updateStatusProgress: "",
            isUpdated: false,
            isTimeout: false,
            statusLoading: false,
            statusLoadingList: [],
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        /*if (this.state.loading === false) {
            this.setState({
                loading: true
            }, () => {
                this.populateData();
            });            
        }*/
        if (!this.props.isLoading)
            this.populateData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //memHomeData = memoize(this.populateDataNew);

    populateData() {
        // get the default house
        if (!this.props.isLoading) {
            this.setState({ loading: true }, async () => {
                await this.populate(this.props.currentCustomerId);
            });
        }
    }

    /*async populateDataNew(propId) {

        await this.populate(propId);
    }*/

    async populate(id) {
        var startTime = performance.now();
        await HomeService.get(id, false).then(response => {
            if (this._isMounted)
                this.setState({ homeList: response.data, currentHome: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                    if (this.state.currentHome !== undefined && this.state.currentHome != null) {

                        await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                            if (this._isMounted)
                                this.setState({ hvacList: response.data, currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                                    // set a selected hvac system, then get the list of each asset for that system.. just like for home, 

                                    if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                                        await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                            if (this._isMounted)
                                                this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                                                   /* await DeviceService.get(this.state.assetList)
                                                        .then(response => {
                                                            if (this._isMounted)
                                                                this.setState({ deviceList: response.data }, () => {
                                                                    this.setState({ loading: false }, () => {
                                                                        var elapsedTime = performance.now() - startTime;
                                                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                    });
                                                                })
                                                        });*/

                                                    await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                                        .then(response => {
                                                            if (this._isMounted)
                                                                this.setState({ deviceList: response.data }, async() => {
                                                                    var macId = this.getMacId(this.state.assetList);
                                                                    await installService.getinstallbymacid(macId).then(response3 => {
                                                                        let install = response3;
                                                                        if (install !== null && install !== undefined) {
                                                                            let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                            this.setState({
                                                                                currentInstall: install,
                                                                                currentInstallTechStr: techStr,
                                                                                loading: false
                                                                            }, () => {
                                                                                var elapsedTime = performance.now() - startTime;
                                                                                Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                            });
                                                                        }
                                                                    })
                                                                    /*this.setState({ loading: false }, () => {
                                                                        var elapsedTime = performance.now() - startTime;
                                                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                    });*/
                                                                })
                                                        });
                                                });
                                        });
                                    }
                                    else {
                                        this.setState({ loading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        });
                                    }

                                });
                        });
                    }
                    else {
                        /*await DeviceService.get(this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, () => {
                                        this.setState({ loading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        });
                                    });
                            });*/

                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, async () => {
                                        var macId = this.getMacId(this.state.assetList);
                                        await installService.getinstallbymacid(macId).then(response3 => {
                                            let install = response3;
                                            if (install !== null && install !== undefined) {
                                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                this.setState({
                                                    currentInstall: install,
                                                    currentInstallTechStr: techStr,
                                                    loading: false
                                                }, () => {
                                                    var elapsedTime = performance.now() - startTime;
                                                    Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                });
                                            }
                                        })
                                        /*this.setState({ loading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        });*/
                                    })
                            });
                    }
                });
        });
    }

    getMacId(assets) {
        if (assets !== null && assets !== undefined && assets.length) {
            if (assets.length > 1) {
                if (assets.filter(a => a.type.trim() !== "Thermostat").length)
                    return assets.filter(a => a.type.trim() !== "Thermostat")[0].mac;
            }
            else
                return assets[0].mac;
        }
    }

    cleanMapping(path) {
        if (path) {
            var namesArray = path.split(" / ");

            for (var i = 0; i < namesArray.length; i++) {
                namesArray[i] = getPrettyName(namesArray[i]);
            }

            var resultString = namesArray.join(" / ");

            return resultString;
        }
        else {
            return "";
        }
    }

    onHVACSystemSelect(e) {
        var startTime = performance.now();
        Event("Customer HVAC System Selected", "User selected one of the customers HVAC systems in dropdown", "Customer HVAC System Selected");
        this.setState({
            currentHVACSystem: this.state.hvacList[e.target.value], newHouseLoading: true
        }, async () => {

            // set a selected hvac system, then get the list of each asset for that system.. just like for home
            await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                if (this._isMounted)
                    this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                        /*await DeviceService.get(this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, () => {
                                        this.setState({ newHouseLoading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("HVAC System Devices Loading", "newHouseLoading", elapsedTime, "Customer Devices Loading for Selected HVAC System");
                                        });
                                    })
                            });*/

                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, async () => {
                                        var macId = this.getMacId(this.state.assetList);
                                        await installService.getinstallbymacid(macId).then(response3 => {
                                            let install = response3;
                                            if (install !== null && install !== undefined) {
                                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                this.setState({
                                                    currentInstall: install,
                                                    currentInstallTechStr: techStr,
                                                    newHouseLoading: false
                                                }, () => {
                                                    var elapsedTime = performance.now() - startTime;
                                                    Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                });
                                            }
                                        })
                                    })
                            });
                    });
            });
        });
    }

    async onHomeSelect(e) {
        let x = e.target.value;

        this.setState({ newHouseLoading: true }, () => {
            this.getDevices(x);
        });
    }

    async getDevices(id) {
        var startTime = performance.now();
        this.setState({
            currentHome: this.state.homeList[id]
        }, async () => {
            await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                if (this._isMounted)
                    this.setState({ hvacList: response.data, currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                        // set a selected hvac system, then get the list of each asset for that system.. just like for home, 

                        if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                            await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                if (this._isMounted)
                                    this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                                        //await DeviceService.get(this.state.assetList)
                                        //    .then(response => {
                                        //        if (this._isMounted)
                                        //            this.setState({ deviceList: response.data }, () => {
                                        //                this.setState({ newHouseLoading: false }, () => {
                                        //                    var elapsedTime = performance.now() - startTime;
                                        //                    Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        //                });
                                        //            })
                                        //    });

                                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                            .then(response => {
                                                if (this._isMounted)
                                                    this.setState({ deviceList: response.data }, async () => {
                                                        var macId = this.getMacId(this.state.assetList);
                                                        await installService.getinstallbymacid(macId).then(response3 => {
                                                            let install = response3;
                                                            if (install !== null && install !== undefined) {
                                                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                this.setState({
                                                                    currentInstall: install,
                                                                    currentInstallTechStr: techStr,
                                                                    newHouseLoading: false
                                                                }, () => {
                                                                    var elapsedTime = performance.now() - startTime;
                                                                    Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                });
                                                            }
                                                        })
                                                    })
                                            });
                                    });
                            });
                        }
                        else {
                            this.setState({ newCustomerLoading: false });
                        }

                    });
            });
        });
    }

    async onAssetClick(index) {
        let x = index;
        let prettyPrint = "";
        var startTime = performance.now();
        Event("Device Clicked", "User clicked on device in table", "Customer Device Clicked");

        this.setState({ assetInfoLoading: true, configLoading: true, currentDevice: jsDevice, isSelected: index }, () => {
            this.setState({
                currentDevice: this.state.deviceList[x], statusMessage: ""
            }, async () => {
                await ProvisioningService.getconfig(this.state.currentDevice.mac)
                    .then(response => {
                        prettyPrint = JSON.stringify(response.data, null, 2);
                        this.setState({ deviceConfiguration: prettyPrint, assetInfoLoading: false, configLoading: false }, () => {
                            var elapsedTime = performance.now() - startTime;
                            Timing("Device Info Loading", "assetInfoLoading", elapsedTime, "Customer Device Info Loading");
                        });
                    });
            });
        });
    }

    onContextClick = (e, data) => {
        this.setState({ devTTUrl: "https://app.lifewhere.com/twins/detail/" + data.name })
        this.openTTConfirmModal();
    }

    showTTConfirmModal() {
        this.setState({ showTTConfirm: !this.state.showTTConfirm });
        window.scrollTo(0, 0);
    }

    openTTConfirmModal() {
        this.setState({ modalMessage: "You are about to leave the LifeWhere portal and be taken to the Device Properties page in TwinThread. Do you wish to continue?", modalTitle: "Leave LifeWhere Portal" }, () => {
            this.showTTConfirmModal();
        });
    }

    onTTConfirm() {
        Event("View Device Properties Clicked", "User clicked view device properties and is being taken to TwinThread Properties view", "View Device Properties Clicked");
        window.location.href = this.state.devTTUrl; //takes user to TwinThread. 
    }

    showConfirmationModal() {
        this.setState({
            showConfirmation: !this.state.showConfirmation
        }, () => {
                if (this.state.showConfirmation)
                    ModalView("repairSensorMapConfirm");
        });
        window.scrollTo(0, 0);
    }

    openConfirmationModal() {
        this.setState({ modalMessage: "Are you sure you wish to repair the sensor map?", modalTitle: "Update Sensor Map" }, () => {
            this.showConfirmationModal();
        });
    }

    updateSensorMap() {

        if (this.state.currentDevice != null && this.state.currentDevice !== undefined && this.state.currentDevice.deviceId !== "") {
            DeviceService.updatesensormap(this.state.currentHVACSystem.assetId, this.state.currentDevice.mac)
                .then(response => {
                    let message = "";

                    if (response.data === true) {
                        message = "Successfully updated Sensor Map!";
                        Event("Repair Sensor Map Clicked", "User clicked repair sensor map button", "Repair Sensor Map Clicked");
                    }
                    else {
                        message = "Failed to update Sensor Map!";
                    }

                    this.setState({ statusMessage: message, showConfirmation: false });

                }).catch(e => {
                    console.log(e);
                });
        }
        else {            
            let message = "No Device selected!";
            this.setState({ statusMessage: message, showConfirmation: false });
        }
    }

    getDate(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("l h:mm:ss A");
        }
        return (date);
    }

    getSigStamp(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("[on] MMM Do, YYYY [at] h:mm A");
        }
        return (date);
    }

    getSigStampAlone(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("MMM Do, YYYY [at] h:mm A");
        }
        return (date);
    }

    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    refreshAllStatus() {
        var statusUpdateList = [];
        for (let i = 0; i < this.state.deviceList.length; i++) {
            statusUpdateList[i] = true;
            this.refreshStatus(i);
        }
        this.setState({ statusLoadingList: statusUpdateList })
    }

    async refreshStatus(index) {
        Event("Refresh Status Clicked", "User refreshed status in Devices page", "Refresh Status Clicked");
        let loadingCheck = [];
        let assetId = parseInt(this.state.deviceList[index].parentId, 10);
        let startDateInt = new Date().getTime();

        this.setState(prevState => ({
            statusLoadingList: {
                ...prevState.statusLoadingList,
                [index]: true
            }
        }));
        this.setState({ statusLoading: true, isTimeout: false, updateStatusProgress: "Running Status Update" });

        // Get the Asset Links and find the Device Status_LPX Thread details
        await AssetService.getassetlinks(assetId)
            .then(response1 =>
            {
                if (response1.data.threads && response1.data.threads.length > 0)
                {
                    var assetThreads = response1.data.threads;
                    var deviceStatusThreads = assetThreads.filter(a => a.name === DEVICE_STATUS_THREAD_NAME);
                    if (deviceStatusThreads && deviceStatusThreads.length > 0) {
                        this.setState({ deviceStatusThread: deviceStatusThreads }, async () => {
                            // Run the Device Status_LPX Thread
                            await threadService.runthread(deviceStatusThreads[0].threadId)
                                .then(response2 => {
                                    if (response2.data && response2.data.blockDebugInfo && response2.data.blockDebugInfo.length > 0 && response2.data.blockDebugInfo[0].outputs.length > 0)
                                    {
                                        this.setState({ deviceStatusThread: deviceStatusThreads }, async () => {
                                            // Get Properties to find Status property
                                            await AssetService.getpropertydatalist(assetId)
                                                .then(response3 => {
                                                    let statusProp = response3.data.find(prop => prop.name === STATUS_PROP_NAME);
                                                    //let updateTime = statusProp.timestamp;
                                                    //let updateTimestamp = new Date(parseInt(updateTime.match(/\d+/)[0], 10));
                                                    //let updateTimeInt = updateTimestamp.getTime();
                                                    let startDate = new Date(startDateInt);
                                                    let endDate = new Date();

                                                    let trendDataRequest = {
                                                        assetId: parseInt(assetId, 10),
                                                        propertyList: [parseInt(statusProp.propertyId, 10)],
                                                        startTime: startDate.toISOString(),
                                                        endTime: endDate.toISOString(),
                                                        maxPoints: 10,
                                                        summaryType: 11
                                                    };
                                                    // Get the Status Property Trend Data
                                                    AlertService.getstringtrenddata(trendDataRequest)
                                                        .then(response4 => {
                                                            if (response4.data && response4.data.length > 0) {
                                                                let latestTimestamp = response4.data[0].timestamps[response4.data[0].timestamps.length - 1];
                                                                let latestTimestampInt = new Date(latestTimestamp).getTime(); 
                                                                // If the Status property was updated since the start of this process, then we have the latest Status value
                                                                if (startDateInt < latestTimestampInt) {
                                                                    let devices = this.state.deviceList;
                                                                    devices[index].status = response4.data[0].values[response4.data[0].values.length - 1];
                                                                    this.setState(prevState => ({
                                                                        statusLoadingList: {
                                                                            ...prevState.statusLoadingList,
                                                                            [index]: false
                                                                        }
                                                                    }));
                                                                    this.setState({ isUpdated: true, deviceList: devices });
                                                                    loadingCheck = this.state.statusLoadingList;
                                                                    let isDoneLoading = Object.keys(loadingCheck).filter(key => loadingCheck[key]).every(v => v === false);
                                                                    if (isDoneLoading) {
                                                                        this.setState({ updateStatusProgress: "Successfully Updated Status!" }, async () => {
                                                                            await this.delay(1000);
                                                                            this.setState({ statusLoading: false, updateStatusProgress: "" });
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                                
                                                        });
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                    this.failedDeviceStatusUpdate(index);
                                                });

                                        });
                                    }
                                    else
                                    {
                                        this.failedDeviceStatusUpdate(index);
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.failedDeviceStatusUpdate(index);
                                });
                        });
                    }
                    else
                    {
                        this.failedDeviceStatusUpdate(index);
                    }
                }
                else {
                    this.failedDeviceStatusUpdate(index);
                }
            })
            .catch(e => {
                console.log(e);
                this.failedDeviceStatusUpdate(index);
            });
    }

    failedDeviceStatusUpdate(index) {
        this.setState(prevState => ({
            statusLoadingList: {
                ...prevState.statusLoadingList,
                [index]: false
            }
        }));
        this.setState({ updateStatusProgress: "Failed to Update Status" }, async () => {
            await this.delay(1000);
            this.setState({ statusLoading: false, updateStatusProgress: "" });
        });
    }

    getDeviceStatus(index) {
        if (this.state.statusLoadingList[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else {
            let deviceStatus = this.state.deviceList[index].status;
            return (
                <span href="#" id="TooltipStatus">{deviceStatus}
                    <RefreshIcon onClick={() => { this.refreshStatus(index) }} />
                </span>
            );
        }
    }

    renderDevices(homeList, deviceList, currentId, loading) {
        const { classes } = this.props;

        let configLoader = this.state.configLoading ?
            <div className={["loading", classes.loader].join(' ')} style = {{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let tableContent = loading ?
            <tbody>
                <tr><td>loading devices...</td></tr>
            </tbody>
            : deviceList.length ?
                <tbody>
                    {deviceList.map((device, index) =>
                        <ContextMenuTrigger
                            renderTag='tr' name={device.parentId}
                            id="devContextMenu" holdToDisplay={1000} data={index} key={device.deviceId}
                            onClick={() => { this.onAssetClick(index) }}
                            collect={collect}>

                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>{device.deviceType}</td>
                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>{device.mac}</td>
                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>{device.nickname}</td>
                            <td className={this.state.isSelected === index ? "tableSelected" : ""}>{this.getDeviceStatus(index)}</td>
                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-temperature">{this.getSigStampAlone(device.deviceTemperature_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipTemperature"><DevTemp devTemp={device.deviceTemperature} /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-battery">{device.batteryVoltage + " " + this.getSigStamp(device.batteryVoltage_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipBattery"><BatterySymbol assetType={this.state.assetList.find(a => a.mac === device.deviceId).type} battVoltage={device.batteryVoltage} /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-wifi">{device.wifiRSSI + " dBm " + this.getSigStamp(device.wifiRSSI_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipWifi"><SignalSymbol sigStrength={device.wifiRSSI} sigType="Wifi" /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="left"
                                    overlay={
                                        <Tooltip id="tooltip-sensor">{device.subgRSSI + " dBm " + this.getSigStamp(device.subgRSSI_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI} sigType="Subg" /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => { this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="left"
                                    overlay={
                                        <Tooltip id="tooltip-current">{this.getSigStampAlone(device.current_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipCurrent">{device.current}</span>
                                </OverlayTrigger>
                            </td>

                        </ContextMenuTrigger>
                    )}
                </tbody>
                
                :
                <tbody>
                    <tr><td>No Devices</td></tr>
                </tbody>;
        let statusLoadingContent = (this.state.statusLoading && !this.state.configLoading) ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <h3 className="status-msg" style={{ textAlign: "center", color: "white" }}>{this.state.updateStatusProgress}</h3>
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div> : <div>{null}</div>;
        let hvacSystemSelect = <SelectHVACSystem style={{ width: 300 + 'px' }} hvacList={this.state.hvacList} onChange={this.onHVACSystemSelect} techStr={this.state.currentInstallTechStr} />
        if (homeList !== null && homeList !== undefined && homeList.length) {
            return ( 
                <div>
                    <div style={{ display: 'none' }}>{currentId}</div>
                    <div className="white-container">
                        <div className="select-container-3">
                        <SelectHome homes={homeList} onChange={this.onHomeSelect} />
                        {hvacSystemSelect}
                        </div>
                    </div>
                    {statusLoadingContent}
                    <br/>
                    <div className="table-container-2">
                    <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                        <thead>
                            <tr >
                                <th>Type</th>
                                <th>Mac</th>
                                <th>Nickname</th>
                                <th>Status <RefreshIcon onClick={this.refreshAllStatus} /></th>
                                <th>Temp</th>
                                <th>Battery</th>
                                <th>WiFi</th>
                                <th>SensorHub</th>
                                <th>Current</th>
                            </tr>
                        </thead>
                        {tableContent}
                    </table>

                        <ContextMenu id="devContextMenu">
                            <MenuItem data={{ action: 'clicked' }} onClick={this.onContextClick}>
                                View Device Properties
                        </MenuItem>
                        </ContextMenu>
                    </div>

                    <div className="white-container">
                        <h5 className="sectionTitle" >Asset Information</h5>
                        <form style={{ marginLeft: 15 + 'px' }}>
                            <div className="row">
                                <div className="col">
                                    <div className="txt-wide">
                                        <label htmlFor="description">Firmware</label>
                                        <label className="form-control" id="firmware">
                                            {this.state.currentDevice.sensorFw}
                                        </label>
                                    </div>
                                </div>
                                <div className="col">
                                <div className="txt-wide">
                                        <label htmlFor="description">Dropouts</label>

                                        <label className="form-control" id="dropouts">
                                            {this.state.currentDevice.dropouts}
                                        </label>                                    
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                <div className="txt-wide">
                                        <label htmlFor="description">Created</label>

                                        <label className="form-control" id="created">
                                            {this.getDate(this.state.currentDevice.createdOn)}
                                        </label>     

                                    </div>
                                </div>
                                <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Last Update</label>

                                        <label className="form-control" id="last-update">
                                            {this.getDate(this.state.currentDevice.lastUpdated)}
                                        </label>     

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Sensor 1</label>

                                    <label className="form-control" id="sensor1">
                                        {this.state.currentDevice.sensor1}
                                    </label>  
                                    </div>
                                    <div className="txt-wide">
                                        <label htmlFor="description">Mapped to</label>

                                        <label className="form-control-text-runoff" id="mapsTo1">
                                            { this.cleanMapping(this.state.currentDevice.mapsTo1) }
                                        </label>

                                    </div>
                                    <div className="txt-wide">
                                        <label htmlFor="description">Sensor 2</label>

                                        <label className="form-control" id="sensor2">
                                            {this.state.currentDevice.sensor2}
                                        </label>

                                    </div>
                                    <div className="txt-wide">
                                        <label htmlFor="description">Mapped to</label>

                                        <label className="form-control-text-runoff" id="mapsTo2">
                                            { this.cleanMapping(this.state.currentDevice.mapsTo2) }
                                        </label>

                                    </div>
                                </div>
                                <div className="col">
                                    <div className="txt-wide">
                                        <label htmlFor="description">Current Config</label>

                                        <label className="form-control" id="current-config" style={{ height: 272 + "px", overflow: "scroll" }}>
                                            <pre>{this.state.deviceConfiguration}</pre>
                                        </label>
                                    </div>
                                </div>
                                {configLoader}
                            </div>
                            

                            <p className="status-msg">{this.state.statusMessage}</p>
                            {(!this.state.isCustomerSupport) && (
                                <button
                                    type="button"
                                    className="secondary-btn btn-small lastItem"
                                    onClick={this.openConfirmationModal}
                                >
                                    Repair Sensor Map
                                </button>)}

                        </form>
                    </div>
                    <br/>
                    <ConfirmationModal show={this.state.showConfirmation} onYes={this.updateSensorMap} onClick={this.showConfirmationModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>
                    <ConfirmationModal show={this.state.showTTConfirm} onYes={this.onTTConfirm} onClick={this.showTTConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>                  
                </div>
            );
        }
        else if (this.props.currentCustomerId === -1) {
            return (
                <div>
                    <p className="status-msg">No customer device information to display</p>
                </div>
            );
        }
        else {
            return (<div style={{ marginLeft: '10px' }}><br/>No Devices located!</div>);
        }
    }

    render() {
        const { classes } = this.props;

        if (this.state.loading) {
            contents = <div className={["loading", classes.loader].join(' ')} style = {{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else if (this.state.newCustomerLoading) {
            contents = <div className={["loading", classes.loader].join(' ')} style = {{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else {
            contents = this.renderDevices(this.state.homeList, this.state.deviceList, this.props.currentCustomerId, this.state.newHouseLoading);
        }

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h3 className="pageTitle" id="tabelLabel">Devices</h3>
                    {contents}
                </div>
            </div>
        );
    }
}


class DevTemp extends React.Component {

    getTemp(temp) {
        let x = temp.indexOf(" ");
        let val = temp.substring(0, x);
        return val;
    }

    render() {

        if (this.props.devTemp == null) {
            return (null);
        }
        else {
            return (
                <div>{this.getTemp(this.props.devTemp)} &deg;F</div>
            );
        }
    }
}

class BatterySymbol extends React.Component {

    render() {
        let red = '#F14047';
        let yellow = '#F6EE59';
        let green = '#5AEB83';

        if (this.props.battVoltage == null) {
            return (null);
        }
        else {
            let assetType = this.props.assetType;
            let voltage = parseFloat(this.props.battVoltage);

            let battPercent = (voltage / 4.5) * 100;

            if (voltage >= 3.5) {
                if ((assetType === "AirConditioner" || assetType === "HeatPump") && voltage >= 4.35) {
                    return (
                        <Battery percent={battPercent} color={red} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
                else {
                    return (
                        <Battery percent={battPercent} color={green} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
            }
            else if (voltage >= 1.5) {
                return (
                    <Battery percent={battPercent} color={yellow} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                );
            }
            else if (voltage < 1.5) {
                return (
                    <Battery percent={battPercent} color={red} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                );
            }
            else {
                return (
                    <p>Error, got { voltage }</p>
                );
            }
        }
    }
}

class SignalSymbol extends React.Component {
    render() {
        let sigStrength = 0;
        let sig = this.props.sigStrength;
        if (this.props.sigType === "Wifi") {
            if (sig === 0) sigStrength = 0;
            else if (sig > -55) sigStrength = 4;
            else if (sig > -75) sigStrength = 3;
            else if (sig > -85) sigStrength = 2;
            else if (sig > -90) sigStrength = 1;
            else sigStrength = 0;
        }
        else {
            if (sig === 0) sigStrength = 0;
            else if (sig > -85) sigStrength = 4;
            else if (sig > -90) sigStrength = 3;
            else if (sig > -100) sigStrength = 2;
            else if (sig > -103) sigStrength = 1;
            else sigStrength = 0;
        }

        if (sigStrength === 4) {
            return (
                <Image src={require('../../img/wifi-excellent.png')} style={{ height: 2 + "em", width: 2.5 + "em" }}/>
                //<WifiIndicator strength='EXCELLENT' /> 
            ); 
        }
        else if (sigStrength === 3) {
            return (
                <Image src={require('../../img/wifi-great.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='GREAT' />
            );
        }
        else if (sigStrength === 2) {
            return (
                <Image src={require('../../img/wifi-okay.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='OKAY' />
            );
        }
        else if (sigStrength === 1) {
            return (
                <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='WEAK' />
            );
        }
        else {
            return (
                <Image src={require('../../img/wifi-unusable.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='DISCONNECTED' />
            );
        }
    }
}

class SelectHVACSystem extends React.Component {
   
    render() {
        if (this.props.hvacList != null) {
            let techInfo = this.props.techStr !== "" && this.props.techStr !== null && this.props.techStr !== undefined ?
                <span style={{ paddingLeft: '20px', color: 'slategrey' }}>Installed By: {this.props.techStr}</span>
                : null;
            return (
                <div className="select-container-2">
                    <span className="select-message">Select which HVAC System<br />
                        <select className="selectpicker" style={{ width: 300 + 'px' }} value={this.props.houseAssetId} onChange={this.props.onChange}>
                            {this.props.hvacList.map((obj, index) =>
                                <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={index}>{getPrettyName(obj.name)}</option>
                            )}
                        </select>
                    </span>
                    {techInfo}
                </div>
            );
        }
        else {
            return (null);
        }
    }
}

export default withStyles(styles)(Devices);