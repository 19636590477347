/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import HomeService from "../../services/home.service";
import AssetService from "../../services/asset.service";
import AlertService from '../../services/alerts.service';
import DeviceService from "../../services/device.service";
import ProvisioningService from "../../services/provisioning.service";

import SelectHome from "../Home/SelectHome";
import InstallCheckBreadcrumb from "./InstallCheckBreadcrumb";
import InstallCheckGraphModal from "./InstallCheckGraphModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { Battery } from '@pxblue/react-progress-icons';
import '../../custom.css';

import memoize from "memoize-one";

import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";
import { jsAsset, jsDevice, jsHouse, jsAssetsGraphData, jsACAssetsGraphData, jsAvgCurrents } from "../../componentObjects";


import moment from 'moment';

import CssBaseline from '@material-ui/core/CssBaseline';
import { emphasize, styled, withStyles, makeStyles } from '@material-ui/core/styles';
import { green, lightGreen } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';



import PropTypes from 'prop-types';
import installService from "../../services/install.service";


const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const FAIL = -1;
const PASS = 1;
const NO_DATA = 0;

var contents = <div className="loading" style={{ display: "block" }}>
    <div className="loading-wrapper">
        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
    </div></div>;

function collect(props) {
    return props;
}

class InstallationCheck extends Component {
    static displayName = InstallationCheck.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onHomeSelect = this.onHomeSelect.bind(this);
        this.getDevices = this.getDevices.bind(this);
        this.onAssetClick = this.onAssetClick.bind(this);
        this.onContextClick = this.onContextClick.bind(this);

        this.openConfirmationModal = this.openConfirmationModal.bind(this);
        this.runInstallCheck = this.runInstallCheck.bind(this);
        this.showConfirmationModal = this.showConfirmationModal.bind(this);
        this.updateSensorMap = this.updateSensorMap.bind(this);
        this.onHVACSystemSelect = this.onHVACSystemSelect.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);

        this.openTTConfirmModal = this.openTTConfirmModal.bind(this);
        this.showTTConfirmModal = this.showTTConfirmModal.bind(this);
        this.onTTConfirm = this.onTTConfirm.bind(this);
        this.openGraphModal = this.openGraphModal.bind(this);
        this.clearAvgCurrentListObject = this.clearAvgCurrentListObject.bind(this);
        this.getWhileOnTestSymbol = this.getWhileOnTestSymbol.bind(this);
        this.getMultictAvgRunAmps = this.getMultictAvgRunAmps.bind(this);

        this.populateData = this.populateData.bind(this);
        this.populate = this.populate.bind(this);

        this.state = {
            deviceList: [],
            homeList: [],
            hvacList: [],
            assetList: [],
            avgCurrentList: jsAvgCurrents,

            currentHVACSystem: jsHouse,
            currentHome: jsHouse,
            currentAsset: jsAsset,
            currentDevice: jsDevice,
            currentInstall: {},
            currentInstallTechStr: "",

            loading: false,
            runningCheck: [],
            lastRunTime: null,
            newCustomerLoading: false,
            newHouseLoading: false,
            assetInfoLoading: false,
            configLoading: false,
            isSelected: -1,
            showConfirmation: false,
            showTTConfirm: false,
            modalMessage: "",
            modalTitle: "",
            runData: jsAssetsGraphData,
            currentRun: {},
            multictRunData: {},
            statusMessage: null,
            showStatusMessage: null,
            tooltipSensorOpen: false,
            setTooltipSensorOpen: true,
            tooltipWifiOpen: false,
            deviceConfiguration: "",
            devTTUrl: "",
            showGraphModal: false,
            graphAssetIndex: 0,
            whileOnCheck: null,
            badDataDate: null,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,

        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        /*if (this.state.loading === false) {
            this.setState({
                loading: true, avgCurrentList: {}, multictRunData: {}
            }, () => {
                this.populateData();
            });
        }*/
        if (!this.props.isLoading)
            this.populateData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memHomeData = memoize(this.populateDataNew);

    populateData() {
        // get the default house
        if (!this.props.isLoading) {
            this.setState({ loading: true, avgCurrentList: {}, multictRunData: {} }, async () => {
                await this.populate(this.props.currentCustomerId);
            })
        }
        
    }

    async populateDataNew(propId) {

        await this.populate(propId);
    }

    clearAvgCurrentListObject() {
        return {
            avgMainCurrent: [],
            avgInducerCurrent: [],
            avgBlowerCurrent: []
        }
    }

    async populate(id) {
        var startTime = performance.now();
        await HomeService.get(id, false).then(response => {
            if (this._isMounted)
                this.setState({ homeList: response.data, currentHome: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse, avgCurrentList: this.clearAvgCurrentListObject(), multictRunData: {} }, async () => {
                    if (this.state.currentHome !== undefined && this.state.currentHome != null) {

                        await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                            if (this._isMounted)
                                this.setState({ hvacList: response.data, currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                                    // set a selected hvac system, then get the list of each asset for that system.. just like for home, 

                                    if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                                        await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                            if (this._isMounted)
                                                this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                                                    /*await DeviceService.get(this.state.assetList)
                                                        .then(response => {
                                                            if (this._isMounted)
                                                                this.setState({ deviceList: response.data }, () => {
                                                                    this.setState({ loading: false }, () => {
                                                                        var elapsedTime = performance.now() - startTime;
                                                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                    });
                                                                })
                                                        });*/
                                                    await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                                        .then(response => {
                                                            if (this._isMounted)
                                                                this.setState({ deviceList: response.data }, async () => {
                                                                    var macId = this.getMacId(this.state.assetList);
                                                                    await installService.getinstallbymacid(macId).then(response2 => {
                                                                        let install = response2;
                                                                        if (install !== null && install !== undefined) {
                                                                            let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                            this.setState({
                                                                                currentInstall: install, currentInstallTechStr: techStr, loading: false
                                                                            }, () => {
                                                                                var elapsedTime = performance.now() - startTime;
                                                                                Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                            });
                                                                        }
                                                                    });
                                                                    /*this.setState({ loading: false }, () => {
                                                                        var elapsedTime = performance.now() - startTime;
                                                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                    });*/
                                                                })
                                                        });
                                                });
                                        });
                                    }
                                    else {
                                        this.setState({ loading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        });
                                    }

                                });
                        });
                    }
                    else {
                        /*await DeviceService.get(this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, () => {
                                        this.setState({ loading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        });
                                    });
                            });*/
                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, async () => {
                                        var macId = this.getMacId(this.state.assetList);
                                        await installService.getinstallbymacid(macId).then(response2 => {
                                            let install = response2;
                                            if (install !== null && install !== undefined) {
                                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                this.setState({
                                                    currentInstall: install, currentInstallTechStr: techStr, loading: false
                                                }, () => {
                                                    var elapsedTime = performance.now() - startTime;
                                                    Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                });
                                            }
                                        });
                                    })
                            });
                    }
                });
        });
    }

    getMacId(assets) {
        if (assets !== null && assets !== undefined && assets.length) {
            if (assets.length > 1) {
                if (assets.filter(a => a.type.trim() !== "Thermostat").length)
                    return assets.filter(a => a.type.trim() !== "Thermostat")[0].mac;
            }
            else
                return assets[0].mac;
        }
    }

    onCommentChange(e) {
        var comment = e.target.value;
        comment = comment.replace("'", "&#39;");

        //this.setState(prevState => ({
        //    newHome: {
        //        ...prevState.newHome,
        //        nickname: nickName
        //    }
        //}));

    }

    onHVACSystemSelect(e) {
        var startTime = performance.now();
        Event("Customer HVAC System Selected", "User selected one of the customers HVAC systems in dropdown", "Customer HVAC System Selected");
        this.setState({
            avgCurrentList: this.clearAvgCurrentListObject(),
            multictRunData: {},
            currentHVACSystem: this.state.hvacList[e.target.value], newHouseLoading: true
        }, async () => {

            // set a selected hvac system, then get the list of each asset for that system.. just like for home
            await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                if (this._isMounted)
                    this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                        /*await DeviceService.get(this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, () => {
                                        this.setState({ newHouseLoading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("HVAC System Devices Loading", "newHouseLoading", elapsedTime, "Customer Devices Loading for Selected HVAC System");
                                        });
                                    })
                            });*/
                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, async () => {
                                        var macId = this.getMacId(this.state.assetList);
                                        await installService.getinstallbymacid(macId).then(response2 => {
                                            let install = response2;
                                            if (install !== null && install !== undefined) {
                                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                this.setState({
                                                    currentInstall: install, currentInstallTechStr: techStr, newHouseLoading: false
                                                }, () => {
                                                    var elapsedTime = performance.now() - startTime;
                                                    Timing("HVAC System Devices Loading", "newHouseLoading", elapsedTime, "Customer Devices Loading for Selected HVAC System");
                                                });
                                            }
                                        });
                                    })
                            });
                    });
            });
        });
    }

    async onHomeSelect(e) {
        let x = e.target.value;

        this.setState({ avgCurrentList: this.clearAvgCurrentListObject(), multictRunData: {},newHouseLoading: true }, () => {
            this.getDevices(x);
        });
    }

    async getDevices(id) {
        var startTime = performance.now();
        this.setState({
            currentHome: this.state.homeList[id]
        }, async () => {
            await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                if (this._isMounted)
                    this.setState({ hvacList: response.data, currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                        // set a selected hvac system, then get the list of each asset for that system.. just like for home, 

                        if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                            await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                if (this._isMounted)
                                    this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                                        /*await DeviceService.get(this.state.assetList)
                                            .then(response => {
                                                if (this._isMounted)
                                                    this.setState({ deviceList: response.data }, () => {
                                                        this.setState({ newHouseLoading: false }, () => {
                                                            var elapsedTime = performance.now() - startTime;
                                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                        });
                                                    })
                                            });*/
                                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                            .then(response => {
                                                if (this._isMounted)
                                                    this.setState({ deviceList: response.data }, async () => {
                                                        var macId = this.getMacId(this.state.assetList);
                                                        await installService.getinstallbymacid(macId).then(response2 => {
                                                            let install = response2;
                                                            if (install !== null && install !== undefined) {
                                                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                this.setState({
                                                                    currentInstall: install, currentInstallTechStr: techStr, newHouseLoading: false
                                                                }, () => {
                                                                    var elapsedTime = performance.now() - startTime;
                                                                    Timing("HVAC System Devices Loading", "newHouseLoading", elapsedTime, "Customer Devices Loading for Selected HVAC System");
                                                                });
                                                            }
                                                        });
                                                    })
                                            });
                                    });
                            });
                        }
                        else {
                            this.setState({ newCustomerLoading: false });
                        }

                    });
            });
        });
    }

    async onAssetClick(index) {
        let x = index;
        let prettyPrint = "";
        var startTime = performance.now();
        Event("Device Clicked", "User clicked on device in table", "Customer Device Clicked");

        this.setState({ assetInfoLoading: true, configLoading: true, currentDevice: jsDevice, isSelected: index }, () => {
            this.setState({
                currentDevice: this.state.deviceList[x], statusMessage: ""
            }, async () => {
                await ProvisioningService.getconfig(this.state.currentDevice.mac)
                    .then(response => {
                        prettyPrint = JSON.stringify(response.data, null, 2);
                        this.setState({ deviceConfiguration: prettyPrint, assetInfoLoading: false, configLoading: false }, () => {
                            var elapsedTime = performance.now() - startTime;
                            Timing("Device Info Loading", "assetInfoLoading", elapsedTime, "Customer Device Info Loading");
                        });
                    });
            });
        });
    }

    onContextClick = (e, data) => {
        this.setState({ devTTUrl: "https://app.lifewhere.com/twins/detail/" + data.name })
        this.openTTConfirmModal();
    }

    showTTConfirmModal() {
        this.setState({ showTTConfirm: !this.state.showTTConfirm });
        window.scrollTo(0, 0);
    }

    openTTConfirmModal() {
        this.setState({ modalMessage: "You are about to leave the LifeWhere portal and be taken to the Device Properties page in TwinThread. Do you wish to continue?", modalTitle: "Leave LifeWhere Portal" }, () => {
            this.showTTConfirmModal();
        });
    }

    onTTConfirm() {
        Event("View Device Properties Clicked", "User clicked view properties button and is being taken to TwinThread", "View Device Properties Clicked");
        window.location.href = this.state.devTTUrl; //takes user to TwinThread. 
    }

    showConfirmationModal() {
        this.setState({
            showConfirmation: !this.state.showConfirmation
        }, () => {
            if (this.state.showConfirmation)
                ModalView("repairSensorMapConfirm");
        });
        window.scrollTo(0, 0);
    }

    openConfirmationModal() {
        this.setState({ modalMessage: "Are you sure you wish to repair the sensor map?", modalTitle: "Update Sensor Map" }, () => {
            this.showConfirmationModal();
        });
    }

    async runInstallCheck() {
        Event("Begin Install Check Clicked", "User clicked begin install check button", "Begin Install Check Clicked");
        var runningCheckList = [];
        for (let i = 0; i < this.state.assetList.length; i++) {
            runningCheckList[i] = true;
        }

        this.setState({ runningCheck: runningCheckList, avgCurrentList: this.clearAvgCurrentListObject(), multictRunData: {} });

        if (this.state.currentDevice != null && this.state.currentDevice !== undefined && this.state.assetList)
        {
            var hoursBack = 6;
            var hoursSinceInstall = 6;
            var secondsSinceInstall = 6*3600;
            var secondsBack = 6*3600;
            var now = moment();
            var before = moment(now).add(-hoursBack, 'hours');
            var beforeStr = before.format();
            var nowStr = now.format();
            var startTime = performance.now();
            var dataRange;

            Event("Asset View Runs Click", "User clicked view runs button", "View Runs Clicked");
            for (const asset in this.state.assetList) {
                var index = asset;
                var assetType = "";
                var installDate;
                var installDateTime;
                for (const prop in this.state.assetList[asset].properties) {
                    if (this.state.assetList[asset].properties[prop].name == "Utility Type")
                        assetType = this.state.assetList[asset].properties[prop].value;
                    else if (this.state.assetList[asset].properties[prop].name == "lifepulseinstalldate") {
                        installDate = this.state.assetList[asset].properties[prop].timestamp;
                        if (installDate) {
                            installDate = installDate.substring(6, 19);
                            installDateTime = new Date(parseInt(installDate)).toISOString();
                            secondsSinceInstall = Math.round(Math.abs(new Date() - new Date(parseInt(installDate))) / 1000);
                            hoursSinceInstall = secondsSinceInstall / 3600;
                        }
                    }
                }

                if (hoursSinceInstall <= hoursBack) {
                    beforeStr = installDateTime;
                    secondsBack = secondsSinceInstall;
                }
                this.setState({ startDateTimeStrPlus: beforeStr, endDateTimeStrPlus: nowStr });

            
                    await AlertService.getassetgraphdata(this.state.assetList[index].name)
                        .then(response => {
                            if (this._isMounted)
                            {
                                this.setState({ currentRun: response.data.dataArray });
                                for (let currentKey in response.data.dataArray) {
                                    if (response.data.dataArray.hasOwnProperty(currentKey)) {
                                        var tempOutput = [];
                                        let currentArray = response.data.dataArray[currentKey];
                                        if (!this.state.deviceList[index].isMultiCT && (currentKey == "blowercurrent" || currentKey == "inducerCurrent")) {
                                            continue;
                                        }
                                        if (secondsBack >= currentArray.length) {
                                            dataRange = currentArray;
                                        }
                                        else {
                                            var runCount = currentArray.length - (secondsBack);
                                            dataRange = currentArray.slice(runCount);
                                        }

                                        var runDataItem = Object.keys(this.state.multictRunData).length > index ? this.state.multictRunData[index] : [];
                                        runDataItem[currentKey] = dataRange;

                                        this.setState(prevState => ({
                                            multictRunData: {
                                                ...prevState.multictRunData,
                                                [index]: runDataItem
                                            }
                                        }));
                                    }
                                }
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                this.getAvgRunAmps(assetType, index);
                if (this.state.deviceList[index].isMultiCT) {
                    this.runWhileOnCheck(index);
                }
                this.setState(prevState => ({
                    runningCheck: {
                        ...prevState.runningCheck,
                        [index]: false
                    }
                }))
            }
            
        }
        var timeNow = (new Date(moment().format())).toLocaleString();
        //this.setState({ lastRunTime: timeNow,  });
    }

    runWhileOnCheck(index) {
        var ctData = this.state.multictRunData[index];
        var isCorrectValueOrder = true;
        var isWithinTolerance = true;
        var badDataDate = "";
        for (let i = 0; i < ctData["current"].length; i++) {
            var mainCurrent = parseFloat(ctData["current"][i].Value);
            var blowerCurrent = parseFloat(ctData["blowercurrent"][i].Value);
            var inducerCurrent = parseFloat(ctData["inducercurrent"][i].Value);
            if (mainCurrent > 0.1 && blowerCurrent > 0.1 && inducerCurrent > 0.1) {
                var avgCurrentDiff = mainCurrent - (blowerCurrent + inducerCurrent);
                if (mainCurrent <= blowerCurrent || mainCurrent <= inducerCurrent || blowerCurrent <= inducerCurrent) {
                    isCorrectValueOrder = false;
                    badDataDate = new Date(moment(ctData["current"][i].Timestamp).format()).toLocaleString();
                    break;
                }
                if (Math.abs(mainCurrent - (inducerCurrent + blowerCurrent)) > (mainCurrent * 0.1)){
                    isWithinTolerance = false;
                    badDataDate = new Date(moment(ctData["current"][i].Timestamp).format()).toLocaleString();
                    break;
                }
            }
        }
        var tempWhileOnCheck = this.state.whileOnCheck != null ? this.state.whileOnCheck : [];
        var tempBadDataDate = this.state.badDataDate != null ? this.state.badDataDate : [];
        if (!isWithinTolerance || !isCorrectValueOrder) {
            tempWhileOnCheck[index] = FAIL;
            tempBadDataDate[index] = badDataDate;
            this.setState({ whileOnCheck: tempWhileOnCheck, badDataDate: tempBadDataDate });
        }
        else {
            tempWhileOnCheck[index] = PASS;
            this.setState({ whileOnCheck: tempWhileOnCheck });
        }
    }

    getAvgRunAmps(assetType, index) {
        var sameValueCheck = 0;
        var sameValueBool = true;
        var runStartIndex = 0;
        var onPointCount = 0;
        var onPointValueSum = 0.0;
        var offPointCount = 0;
        var offPointValueSum = 0.0;
        var offHigh = assetType == "AirConditioner" ? 0.2 : 0.3;
        var longestStretch = 0;
        var longestAvg = 0;
        var testResult = [];
        var tempOutput = [];
        var run = this.state.multictRunData[index]["current"];

        for (const point in run)
        {
            //Check if Every Point is the Same:
            if (point == 0) {
                sameValueCheck = run[point].Value;
            }
            else if (run[point].Value != sameValueCheck) {
                sameValueBool = false;
            }

            // Check on values and grab chunks of 30+ contiguous values above offHigh
            if (run[point].Value > offHigh) {
                onPointCount++;
                onPointValueSum += parseFloat(run[point].Value);
            }
            else if (run[point].Value <= offHigh && run[point].Value > 0) {
                if (onPointCount >= 30 && onPointCount > longestStretch)
                { 
                    longestStretch = onPointCount;
                    longestAvg = onPointValueSum / onPointCount;
                    runStartIndex = point - (onPointCount);
                }

                onPointCount = 0;
                onPointValueSum = 0.0;
                offPointCount++;
                offPointValueSum += parseFloat(run[point].Value);
            }
        }

        if (onPointCount >= 30 && onPointCount > longestStretch)
        {
            longestStretch = onPointCount;
            longestAvg = onPointValueSum / onPointCount;
            runStartIndex = (run.length - 1) - (onPointCount);
        }

        if (sameValueBool) {
            testResult = [0, NO_DATA];
        }
        else if (longestStretch > 30) {
            testResult = [longestAvg, PASS];
        }
        else if (offPointCount > 0) {
            var avg = (offPointValueSum / offPointCount);
            testResult = [avg, FAIL];
        }
        else if (onPointCount > 0) {
            testResult = [0,FAIL];
        }
        else
            testResult = [0, NO_DATA];

        testResult[0] = testResult[0].toFixed(2);
        tempOutput[index] = testResult;
        var currentListItem = [];
        currentListItem = this.state.avgCurrentList["avgMainCurrent"];
        currentListItem[index] = tempOutput[index];

        this.setState(prevState => ({
            avgCurrentList: {
                ...prevState.avgCurrentList,
                ["avgMainCurrent"]: currentListItem
            }
        }));

        if (this.state.deviceList[index].isMultiCT) {
            var runEndIndex = runStartIndex + longestStretch;
            this.getMultictAvgRunAmps(index, runStartIndex, runEndIndex)
        }
    }

    getMultictAvgRunAmps(index, startIndex, endIndex) {
        var passValue = 0;
        var avgCurrent = 0;
        var testResultKey = "";
        var currentListItem = [];
        var tempOutput = [];

        if (this.state.deviceList[index].isMultiCT) {
            var blowerCurrent = this.state.multictRunData[index]["blowercurrent"].slice(startIndex, endIndex + 1);
            var inducerCurrent = this.state.multictRunData[index]["inducercurrent"].slice(startIndex, endIndex + 1);
        }

        for (let key in this.state.multictRunData[index]) {

            if (this.state.multictRunData[index].hasOwnProperty(key)) {

                var testResult = [];

                if (key == "current") {
                    continue;
                }
                if (key == "blowercurrent") {
                    passValue = 0.4;
                    testResultKey = "avgBlowerCurrent";
                }
                else if (key == "inducercurrent") {
                    passValue = 0.1;
                    testResultKey = "avgInducerCurrent";
                }
                try {
                    var current = this.state.multictRunData[index][key].slice(startIndex, endIndex + 1);
                    avgCurrent = current.map(a => parseFloat(a.Value)).reduce((b, c) => b + c, 0) / current.length;
                }
                catch (error) { }

                if (avgCurrent == 0) {
                    testResult = [0, FAIL];
                }
                else if (avgCurrent >= passValue) {
                    testResult = [avgCurrent, PASS];
                }
                else if (avgCurrent < passValue) {
                    testResult = [avgCurrent, FAIL];
                }
                else {
                    testResult = [0, NO_DATA];
                }

                testResult[0] = testResult[0].toFixed(2);
                tempOutput[index] = testResult;
                currentListItem = this.state.avgCurrentList[testResultKey];
                currentListItem[index] = tempOutput[index];

                this.setState(prevState => ({
                    avgCurrentList: {
                        ...prevState.avgCurrentList,
                        [testResultKey]: currentListItem
                    }
                }));
            }
        }
    }

    updateSensorMap() {

        if (this.state.currentDevice != null && this.state.currentDevice !== undefined && this.state.currentDevice.deviceId !== "") {
            DeviceService.updatesensormap(this.state.currentHVACSystem.assetId, this.state.currentDevice.mac)
                .then(response => {
                    let message = "";

                    if (response.data === true) {
                        message = "Successfully updated Sensor Map!";
                        Event("Repair Sensor Map Clicked", "User clicked repair sensor map button", "Repair Sensor Map Clicked");
                    }
                    else {
                        message = "Failed to update Sensor Map!";
                    }

                    this.setState({ statusMessage: message, showConfirmation: false });

                }).catch(e => {
                    console.log(e);
                });
        }
        else {
            let message = "No Device selected!";
            this.setState({ statusMessage: message, showConfirmation: false });
        }
    }

    getDate(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("l h:mm:ss A");
        }
        return (date);
    }

    getSigStamp(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("[on] MMM Do, YYYY [at] h:mm A");
        }
        return (date);
    }

    getSigStampAlone(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("MMM Do, YYYY [at] h:mm A");
        }
        return (date);
    }

    getAvgCurrentSymbol(index, ct) {
        if (this.state.runningCheck[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else if (Object.keys(this.state.runningCheck).length > 0 && this.state.avgCurrentList[ct]?.length > index) {
            return (
                <span href="#" id="TooltipCurrent">{this.state.avgCurrentList[ct][index][0]}
                    <CurrentSymbol avgCurrent={this.state.avgCurrentList[ct][index]} />
                </span>
            );
        }
        else {
            return (
                <span href="#" id="TooltipCurrent">0.0
                    <CurrentSymbol avgCurrent={this.state.avgCurrentList[ct][index]} />
                </span>
            );
        }
            
    }
    getTestResultSymbol(index) {
        if (this.state.runningCheck[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else {
            var testResult = null;
            if (this.state.deviceList[index].isMultiCT && this.state.avgCurrentList["avgMainCurrent"]?.length > 0) {
                var mainTest = this.state.avgCurrentList["avgMainCurrent"][index][1];
                var inducerTest = this.state.avgCurrentList["avgInducerCurrent"][index][1];
                var blowerTest = this.state.avgCurrentList["avgBlowerCurrent"][index][1];

                if (mainTest == PASS && inducerTest == PASS && blowerTest == PASS)
                    testResult = [0, PASS];
                else if (mainTest == NO_DATA && inducerTest == NO_DATA && blowerTest == NO_DATA)
                    testResult = [0, NO_DATA];
                else
                    testResult = [0, FAIL];
            }
            else {
                testResult = this.state.avgCurrentList["avgMainCurrent"][index];
            }
            return (
                <TestResultSymbol avgCurrent={testResult} />
            );
        }
    }

    getWhileOnTestSymbol(index) {
        if (this.state.runningCheck[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else if (this.state.whileOnCheck == null || this.state.whileOnCheck.length <= index && this.state.whileOnCheck[index] == null) {
            return (null);
        }
        else if (this.state.whileOnCheck[index] == PASS) {
            return (
                <CheckCircleOutlineOutlinedIcon style={{ color: '#5AEB83', fontSize: 40 }} />
            );
        }
        else if (this.state.whileOnCheck[index] == FAIL) {
            return (
                <span href="#" id="TooltipCurrent">
                    <HighlightOffOutlinedIcon style={{ color: '#F14047', fontSize: 40 }} />
                    {this.state.badDataDate[index]}
                    </span>
            );
        }
        else if (this.state.whileOnCheck[index] == NO_DATA) {
            return (
                <RadioButtonUncheckedOutlinedIcon style={{ color: '#9e9e9e', fontSize: 40 }} />
            );
        }
    }

    openGraphModal(index = 0) {
        this.setState({ showGraphModal: !this.state.showGraphModal, graphAssetIndex: index }, () => {
            if (this.state.showGraphModal)
                ModalView('installCheckGraphModal');
        });
    }

    renderDevices(homeList, deviceList, currentId, loading) {
        const { classes } = this.props;

        var isAdmin = this.props.roles.includes("ADMIN") && !this.props.roles.includes("PARTNER")
        var whileOnCheckHeading = isAdmin ? "WHILE ON CHECK" : null;

        let testHeadings = this.state.deviceList.some(a => a.isMultiCT == true) ?
             <tr className="device-table-row">
                <th style={{ padding: '0.1px 0.1px 0.1px 10px' }}>NICKNAME</th>
                <th>MAC</th>
                <th>STATUS</th>
                <th>TEMP</th>
                <th>BATTERY</th>
                <th>WIFI</th>
                <th>SENSOR HUB</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>BLOWER CURRENT</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>INDUCER CURRENT</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>MAIN CURRENT</th>
                <th>{whileOnCheckHeading}</th>
                <th>TEST RESULT</th>
                <th>VIEW GRAPHS</th>
                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
            </tr>
            : <tr className="device-table-row">
                <th style={{ padding: '0.1px 0.1px 0.1px 10px' }}>NICKNAME</th>
                <th>MAC</th>
                <th>STATUS</th>
                <th>TEMP</th>
                <th>BATTERY</th>
                <th>WIFI</th>
                <th>SENSOR HUB</th>
                <th style={{ padding: '10px 50px 10px 0px' }}>AVERAGE CURRENT</th>
                <th>TEST RESULT</th>
                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
            </tr>


        let configLoader = this.state.configLoading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let tableContent = loading ?
            <tbody>
                <tr><td>loading devices...</td></tr>
            </tbody>
            : deviceList.length ?
                this.state.deviceList.some(a => a.isMultiCT == true) ?
                    <tbody>
                        {deviceList.map((device, index) =>
                            [<tr className={"device-table-row"} key={`${device.deviceId}-tableRow`} data={index} value={index}>
                                <td style={{ padding: '0.1px 0.1px 0.1px 10px' }}>{device.nickname}</td>
                                <td>{device.mac}</td> 
                                <td>{device.status}</td>
                                <td  className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTemperature"><DevTemp devTemp={device.deviceTemperature} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipBattery"><BatterySymbol assetType={this.state.assetList.find(a => a.mac === device.deviceId).type} battVoltage={device.batteryVoltage} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipWifi"><SignalSymbol sigStrength={device.wifiRSSI} sigType="Wifi" /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                        <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI} sigType="Subg" /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgBlowerCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgInducerCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgMainCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {isAdmin ? this.getWhileOnTestSymbol(index) : null}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTestResult">
                                        {this.getTestResultSymbol(index)}
                                    </span>
                                </td>
                                <td>
                                    <button
                                        disabled={Object.keys(this.state.multictRunData).length < index + 1}
                                        type="button"
                                        className={Object.keys(this.state.multictRunData).length < index + 1 ? "secondary-btn" : "primary-btn"}
                                        style={{ borderRadius: ".75rem" }}
                                        onClick={() => { this.openGraphModal(index) }}
                                    >
                                        View
                                        </button>
                                </td>
                            </tr>
                        ])}
                    </tbody>
                    :
                    <tbody>
                        {deviceList.map((device, index) =>
                            [<tr className={"device-table-row"} key={`${device.deviceId}-tableRow`} data={index} value={index}>

                                <td style={{ padding: '0.1px 0.1px 0.1px 10px' }}>{device.nickname}</td>
                                <td>{device.mac}</td>
                                <td>{device.status}</td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTemperature"><DevTemp devTemp={device.deviceTemperature} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipBattery"><BatterySymbol assetType={this.state.assetList.find(a => a.mac === device.deviceId).type} battVoltage={device.batteryVoltage} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipWifi"><SignalSymbol sigStrength={device.wifiRSSI} sigType="Wifi" /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI} sigType="Subg" /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgMainCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTestResult">
                                        {this.getTestResultSymbol(index)}
                                    </span>
                                </td>
                            </tr>
                            ])}
                    </tbody>
                :
                <tbody>
                    <tr><td>No Devices</td></tr>
                </tbody>;

        let hvacSystemSelect = <SelectHVACSystem style={{ width: 300 + 'px' }} hvacList={this.state.hvacList} onChange={this.onHVACSystemSelect} techStr={this.state.currentInstallTechStr} />

        if (homeList !== null && homeList !== undefined && homeList.length) {
            let leadingButtonText = 'Once Installation is Complete, Press Button to   ';
            let runningCheckLabel = 'Running Installation Check   ';
            let lastCheckTimeLabel = 'Last Install Check Run on  ';

            let runningCheckSpinner = this.state.runningCheck[this.state.assetList.length - 1] ? <div className="modal-body">{runningCheckLabel}<Spinner animation="border" variant="dark" /></div> :
                <div />

            let lastCheckRunTime = this.state.lastRunTime ? <div>{lastCheckTimeLabel}{this.state.lastRunTime}</div> : <div />;

            return (
                <div>
                    <div style={{ display: 'none' }}>{currentId}</div>
                    <div className="white-container">
                        <div className="install-check-container">
                            <InstallCheckBreadcrumb homes={this.state.homeList} hvacList={this.state.hvacList} onChange={this.onHomeSelect} onHvacChange={this.onHVACSystemSelect} techStr={this.state.currentInstallTechStr} />
                        </div>
                        {(!this.state.isCustomerSupport) && (
                            <div className="install-check-container center">
                                {leadingButtonText}
                                <button
                                    disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                                    type="button"
                                    className="primary-btn btn-center"
                                    onClick={this.runInstallCheck}
                                >
                                    Begin Installation Check
                                </button>
                                {runningCheckSpinner}

                                {/*lastCheckRunTime*/}
                            </div>)}
                        
                    </div>
                    <br />

                    <div>
                        <table className="table-hover" style={{ width: '100%' }}>
                            <thead>
                                {testHeadings}
                            </thead>
                            {tableContent}
                        </table>
                        {/*
                         <SimplePopper />
                        */}
                    </div>

                  

                    <div className="white-container">
                        {/*
                        <h5 className="sectionTitle" >Reports</h5>
                        */}
                        <form style={{ marginLeft: 15 + 'px' }}>
                          <p className="status-msg">{this.state.statusMessage}</p>
                        </form>
                    </div>
                    <br />
                    <ConfirmationModal show={this.state.showConfirmation} onYes={this.updateSensorMap} onClick={this.showConfirmationModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>
                    <ConfirmationModal show={this.state.showTTConfirm} onYes={this.onTTConfirm} onClick={this.showTTConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>
                </div>
            );
        }
        else if (this.props.currentCustomerId == -1) {
            return (
                <div>
                    <p className="status-msg">No customer device information to display</p>
                </div>
            );
        }
        else {
            return (<div style={{ marginLeft: '10px' }}><br />No Devices located!</div>);
        }
    }


    render() {
        const { classes } = this.props;

        if (this.state.loading) {
            contents = <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else if (this.state.newCustomerLoading) {
            contents = <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else {
            contents = this.renderDevices(this.state.homeList, this.state.deviceList, this.props.currentCustomerId, this.state.newHouseLoading);
        }

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Installation Check
                    </h2>
                    {contents}
                    <InstallCheckGraphModal dataObject={this.state.multictRunData[this.state.graphAssetIndex]} show={this.state.showGraphModal} onClick={() => { this.openGraphModal() }} />
                </div>
            </div>
            
        );
    }
}

class SimplePopper extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = (event) => {
        var anchorValue = this.state.anchorEl ? null : event.currentTarget;
        this.setState({ anchorEl: anchorValue});
    };


    render() {
        const { anchorEl } = this.state;
        

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popper' : undefined;

        var commentValue = "";
    
        return (
                <div>
                    <button className='primary-btn btn-center' aria-describedby={id} type="button" onClick={this.handleClick}>
                        Comments/Observations
                    </button>
                    <Popper id={id} open={open} anchorEl={anchorEl} placement='right-end' transition>
                    <TextareaAutosize
                        className='form-control'
                        maxRows={10}
                        maxLength={50}
                        aria-label="maximum height"
                        placeholder="Enter Comments and Observations"
                        style={{ width: 300 + 'px', height: 200 + 'px' }}
                        defaultValue={commentValue}
                    />
                    </Popper>
                </div >
            );
        }
}

class DevTemp extends React.Component {

    getTemp(temp) {
        let x = temp.indexOf(" ");
        let val = temp.substring(0, x);
        return val;
    }

    render() {
        if (this.props.devTemp == null) {
            return (null);
        }
        else {
            return (
                <div>{this.getTemp(this.props.devTemp)} &deg;F</div>
            );
        }
    }
}

class BatterySymbol extends React.Component {

    render() {
        let red = '#F14047';
        let yellow = '#F6EE59';
        let green = '#5AEB83';

        if (this.props.battVoltage == null) {
            return (null);
        }
        else {
            let assetType = this.props.assetType;
            let voltage = parseFloat(this.props.battVoltage);

            let battPercent = (voltage / 4.5) * 100;

            if (voltage >= 3.5) {
                if ((assetType === "AirConditioner" || assetType === "HeatPump") && voltage >= 4.35) {
                    return (
                        <Battery percent={battPercent} color={red} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
                else {
                    return (
                        <Battery percent={battPercent} color={green} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
            }
            else if (voltage >= 1.5) {
                return (
                    <Battery percent={battPercent} color={yellow} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                );
            }
            else if (voltage < 1.5) {
                return (
                    <Battery percent={battPercent} color={red} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                );
            }
            else {
                return (
                    <p>Error, got { voltage}</p>
                );
            }
        }
    }
}

class CurrentSymbol extends React.Component {


    render() {
        if (this.props.avgCurrent == null) {
            return (null);
        }
        else {
            let current = parseFloat(this.props.avgCurrent[0]);
            let result = this.props.avgCurrent[1];
            //#F14047 Red , #5AEB83 Green

            if (result == PASS) {
                return (
                    <FlashOnOutlinedIcon style={{ color: '#5AEB83', fontSize: 40 }} />
                );
            }
            else if (result == FAIL) {
                return (
                    <FlashOnOutlinedIcon style={{ color: '#F14047', fontSize: 40 }} />
                );
            }
            else if (result == NO_DATA) {
                return (
                    <FlashOnOutlinedIcon style={{ color: '#9e9e9e', fontSize: 40 }} />
                );
            }
            else {
                return (
                    <p>Error, got { current }</p>
                );
            }
        }
    }
}

class TestResultSymbol extends React.Component {

    render() {

        if (this.props.avgCurrent == null) {
            return (null);
        }
        else {
            //let current = parseFloat(this.props.avgCurrent);
            let result = this.props.avgCurrent[1];

            //#F14047 Red , #5AEB83 Green

            if (result == PASS) {
                return (
                    <CheckCircleOutlineOutlinedIcon style={{ color: '#5AEB83', fontSize: 40 }} />
                );
            }
            else if (result == FAIL) {
                return (
                    <HighlightOffOutlinedIcon style={{ color: '#F14047', fontSize: 40 }} />
                );
            }
            else if (result == NO_DATA) {
                return (
                    <RadioButtonUncheckedOutlinedIcon style={{ color: '#9e9e9e', fontSize: 40 }} />
                );
            }
            else {
                return (
                    <p>Test Error</p>
                );
            }
        }
    }
}

class SignalSymbol extends React.Component {
    render() {
        let sigStrength = 0;
        let sig = this.props.sigStrength;
        if (this.props.sigType == "Wifi") {
            if (sig === 0) sigStrength = 0;
            else if (sig > -55) sigStrength = 4;
            else if (sig > -75) sigStrength = 3;
            else if (sig > -85) sigStrength = 2;
            else if (sig > -90) sigStrength = 1;
            else sigStrength = 0;
        }
        else {
            if (sig === 0) sigStrength = 0;
            else if (sig > -85) sigStrength = 4;
            else if (sig > -90) sigStrength = 3;
            else if (sig > -100) sigStrength = 2;
            else if (sig > -103) sigStrength = 1;
            else sigStrength = 0;
        }

        if (sigStrength === 4) {
            return (
                <Image src={require('../../img/wifi-excellent.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='EXCELLENT' /> 
            );
        }
        else if (sigStrength === 3) {
            return (
                <Image src={require('../../img/wifi-great.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='GREAT' />
            );
        }
        else if (sigStrength === 2) {
            return (
                <Image src={require('../../img/wifi-okay.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='OKAY' />
            );
        }
        else if (sigStrength === 1) {
            return (
                <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='WEAK' />
            );
        }
        else {
            return (
                <Image src={require('../../img/wifi-unusable.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='DISCONNECTED' />
            );
        }
    }
}

class SelectHVACSystem extends React.Component {
    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            return split[0];
        }
        else {
            return "";
        }
    }

    render() {
        if (this.props.hvacList != null) {
            let techInfo = this.props.techStr !== "" && this.props.techStr !== null && this.props.techStr !== undefined ?
                <span style={{ paddingLeft: '20px', color: 'slategrey' }}>Installed By: {this.props.techStr}</span>
                : null;
            return (
                <div className="select-container-2">
                    <span className="select-message">Select which HVAC System<br />
                        <select display="block" className="selectpicker" style={{ width: 300 + 'px' }} value={this.props.houseAssetId} onChange={this.props.onChange}>
                            {this.props.hvacList.map((obj, index) =>
                                <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={index}>{this.getPrettyName(obj.name)}</option>
                            )}
                        </select>
                    </span>
                    {techInfo}
                </div>
            );
        }
        else {
            return (null);
        }
    }
}

export default withStyles(styles)(InstallationCheck);