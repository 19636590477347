/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/device";
class DeviceService {
    
    async get(assets) {
        const response = await http.post(`${controller}/getdevices/`, assets);
        const data = await response;
        return data;
    }

    async updatesensormap(houseAssetId, deviceId){
        const response = await http.post(`${controller}/updatesensormap?houseAssetId=${houseAssetId}&deviceId=${deviceId}`);
        const data = await response;
        return data;
    }

    /*async getconfig(deviceMac) {
        const response = await http.get("/devicelist/getdeviceconfig?deviceMac=" + deviceMac);
        const data = await response;
        return data;
    }*/
}

export default new DeviceService();