/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';

class SelectHome extends React.Component {
    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            name = split[0].replace("?", "'");

            const indexOfSlash = name.indexOf(' / ');
            if (indexOfSlash !== -1) {
                name = name.substring(indexOfSlash + 3);
            }
            return name;
        }
        else {
            return "";
        }
    }

    render() {
        if (this.props.homes != null) {
            return (
                <div className="select-container-2">
                    <span className="select-message">Select which house<br />
                        <select className="selectpicker" style={{ width: 300 + 'px' }} value={this.props.selectedIndex} onChange={this.props.onChange}>
                            {this.props.homes.map((obj, index) =>
                                <option key={`${index}-${obj.orgId}`} id={`${index}-${obj.orgId}`} value={index}>{this.getPrettyName(obj.nickname)}</option>
                            )}
                        </select>
                    </span>
                </div>
            );
        }
        else {
            return (<div className="select-container-2">
                <span className="select-message">Select which house<br />
                    <select className="selectpicker" style={{ width: 300 + 'px' }} >
                        
                    </select>
                </span>
            </div>);
        }
    }
} 
export default SelectHome;