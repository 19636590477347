/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component, useState, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core';
import TitanIntegration from './TitanIntegration';
import * as CryptoJS from 'crypto-js';
import serviceIntegration from '../../services/serviceIntegration.service';
import uuid from 'react-uuid';
import ServiceTitanService from "../../services/servicetitan.service";



const IntegrationsList = [
    {
        IntegrationName: "Select Integrations Service",
        IntegrationID: 0
    },
    {
        IntegrationName: "Service Titan Integration",
        IntegrationID: 1
    },
    {
        IntegrationName: "Integration 2",
        IntegrationID: 2
    },
    {
        IntegrationName: "Integration 3",
        IntegrationID: 3
    },
    {
        IntegrationName: "Integration 4",
        IntegrationID: 4
    }
];


const addService = {
    id: 0,
    CreateUserId: "",
    UpdateUserId: "",
    IsActive: true,
    InitialTimeStamp: "",
    UpdatedTimeStamp: "",
    ServiceType: "",
    ServiceIntegrationObject: "",
    PartnersId: "",
    UniqueServiceId : 0
}

const secretPass = "XkhZG4fW2t2W";

function IntegrationModle(props) {

    const [Integrations, setIntegrations] = useState(IntegrationsList);
    const [selectedService, setSelectedService] = useState(props?.data?.serviceType);
    const [TitanServiceData, setTitanServiceData] = useState(JSON.parse(props?.data?.serviceIntegrationObject));
    const [isSaveEnable, setIsSaveEnable] = useState(props?.isSaveEnable);
    const [isTitanValidationSucess, SetisTitanValidationSucess] = useState(true);
    const [isServiceTitanEdit, setIsServiceTitanEdit] = useState(props?.isSTEdit);
    const [isValidService, setIsValidService] = useState(true);
    const [validTitanServiceData, setvalidTitanServiceData] = useState(JSON.parse(props?.data?.serviceIntegrationObject));
    const [validationMessages, setValidationMessages] = useState({enable : false , color: "", Messages :""});

    useEffect(() => {
        if (TitanServiceData && validTitanServiceData &&
            TitanServiceData.TenantId.length > 0 && TitanServiceData.ClientId.length > 0 && TitanServiceData.APIKey.length > 0 && TitanServiceData.ClientSecret.length > 0 &&
            (TitanServiceData.TenantId === validTitanServiceData.TenantId) && (TitanServiceData.ClientId == validTitanServiceData.ClientId) &&
            (TitanServiceData.APIKey === validTitanServiceData.APIKey) && (TitanServiceData.ClientSecret == validTitanServiceData.ClientSecret))
            {
            setIsValidService(false);
        }
        else {
            setIsValidService(true);
        }
    }, [TitanServiceData, validTitanServiceData]);


    const encryptData = (text) => {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            secretPass
        ).toString();

        return data;
    };

    const decryptData = (text) => {
        const bytes = CryptoJS.AES.decrypt(text, secretPass);
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
  
    const onCancelClick = () => {
        setIsSaveEnable(false);
        props.onChage(false);
    };

    const onTitanIntegration = (data) => {
        setTitanServiceData(data);
    };

    const onTitanValidation = (e) =>
         { 
       // SetisTitanValidationSucess(e);
	 }

    const TitanValidation = () => {
        return (TitanServiceData.TenantId.length > 0 && TitanServiceData.ClientId.length > 0 && TitanServiceData.APIKey.length > 0 && TitanServiceData.ClientSecret.length > 0) ? true : false;
	}

    const onSaveIntegration = (e) => {
        e.preventDefault();
        var selectedID = Integrations.find(x => x.IntegrationName == selectedService)?.IntegrationID;
        let serviceData;
        if (selectedID != undefined) {
            switch (selectedID) {
                case 1:
                    if (TitanValidation()) {
                        AddService(TitanServiceData);
                    }
                    break;
                default:
                    break
            }
        }
    };

    const onValidIntegration = (e) => {
        e.preventDefault();
        setValidationMessages(() =>({ enable: true, color: "blue", Messages: "Validation in progress ..." }),2000);
        var selectedID = Integrations.find(x => x.IntegrationName == selectedService)?.IntegrationID;
        let serviceData;
        if (selectedID != undefined) {
            switch (selectedID) {
                case 1:
                    if (TitanValidation()) {
                        ServiceValidation(TitanServiceData);
                    }
                    break;
                default:
                    break
            }
        }
    };

    const AddService = (serviceData) => 
    {
        addService.CreateUserId = props?.currentAccount?.email;
        addService.id = props?.data?.id;
        addService.UpdateUserId = props?.currentAccount?.email;
        addService.InitialTimeStamp = new Date();
        addService.UpdatedTimeStamp = new Date();
        addService.ServiceType = selectedService;
        addService.ServiceIntegrationObject = JSON.stringify(serviceData); 
        addService.PartnersId = props?.partnersId;
        addService.UniqueServiceId = (props?.data?.id != 0) ? props?.data?.uniqueServiceId : uuid();

        serviceIntegration.addService(addService)
            .then(response => {
                //if (response && response?.data) {
                //    //setIntegration(response?.data);
                //}

                setIsSaveEnable(false);
                props.onChage(false);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const ServiceValidation = (serviceData) => {

        ServiceTitanService.serviceValidation(serviceData)
            .then(response => {
                if (response && response?.data) {
                    const TitanService = {
                        ClientSecret: response?.data?.clientSecret,
                        APIKey: response?.data?.apiKey,
                        ClientId: response?.data?.clientId,
                        TenantId: response?.data?.tenantId
                    }
                    setvalidTitanServiceData(TitanService);
                    if (response?.data?.clientSecret != null && response?.data?.apiKey != null && response?.data?.clientId != null && response?.data?.tenantId != null) {

                        setValidationMessages({ enable: true, color: "green", Messages: "Valid service data" });
                    } else {

                        setValidationMessages(() => ({ enable: true, color: "red", Messages: "Invalid service data" }));

                    }
                    setTimeout(() => { setValidationMessages({ enable: false, color: "", Messages: "" }) }, 2000);

                }
            })
            .catch(e => {
                console.log(e);
                setValidationMessages(() => ({ enable: true, color: "red", Messages: "Invalid service data" }));
                setTimeout(() => { setValidationMessages({ enable: false, color: "", Messages: "" }) }, 2000);
               
            });
    }
   

    const renderIntegrations = () =>
    {
        var selectedID = Integrations.find(x => x.IntegrationName == selectedService)?.IntegrationID;

        if (selectedID == undefined || selectedID == null) 
	    {
            selectedID = 0;
        };
        
        switch (selectedID) {
            case 1:
                return <TitanIntegration data={TitanServiceData} onTitanIntegrationChange={onTitanIntegration} onValidationChange={onTitanValidation} isSTEdit={isServiceTitanEdit} />
            case 2:

                return <div className="col-sm-12 col-md-9">
                    <div style={{ paddingRight: 2 + "px" }} className="form-group">
                        <label htmlFor="Integration1">Integration1</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Integration1"

                        />
                    </div>
                </div>;
            case 3:
                return <div className="col-sm-12 col-md-9">
                    <div style={{ paddingRight: 2 + "px" }} className="form-group">
                        <label htmlFor="Integration3">Integration3</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Integration3"
                        />
                    </div>
                </div>;
            case 4:
                return <div className="col-sm-12 col-md-9">
                    <div style={{ paddingRight: 2 + "px" }} className="form-group">
                        <label htmlFor="Integration4">Integration4</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Integration4"
                        />
                    </div>
                </div>;
            case 0:
            default:
                return <div className="col-sm-12 col-md-9">
                    <div className="form-group">
                        <label htmlFor="NoService">No Service</label>
                    </div>
                </div>;
        }
    
    };

   const handleChange = (event) => {
       setSelectedService(event.target.value);
       var selectedID = Integrations.find(x => x.IntegrationName == event.target.value).IntegrationID;
       (selectedID != 0 && selectedID != undefined) ?
           setIsSaveEnable(true) : setIsSaveEnable(false);

    };

    return (
        <div>
            <div className="modal" style={{ display: isSaveEnable ? "block" : "none" }}>
                <div className="modal-wrapper"
                    style={{
                        transform: isSaveEnable ? 'translateY(0vh)' : 'translateY(-100vh)',
                        opacity: isSaveEnable ? '1' : '0'
                    }}>

                    <div className="modal-header">
                        <h3>Add Integrations</h3>
                        <span className="close-modal-btn" onClick={ onCancelClick }>×</span>
                    </div>

                    <form>
                        <div className="row" style={{ minheight: 200}}>
                            <div className="col-sm-12 col-md-6">
                                <div className="row no-gutters">
                                    <div className="col-5">
                                        <p className="semibold">Service:</p>
                                    </div>
                                    <div className="col">
                                        <select className="txt-detail" value={selectedService} onChange={handleChange}>
                                            {Integrations.map((value) => 
                                                <option key={value.IntegrationID} id={value.IntegrationID} value={value.IntegrationName}> {value.IntegrationName}</option>
					                         )}
                                           
                                        </select>
                                    </div>
                                </div>
                               
                            </div> 
                        </div>
                        <div className="row" style={{ minheight: 200 }}>
                            <div className="col-sm-12">
                                {renderIntegrations()}
                            </div>s
                        </div>
                        {isSaveEnable &&
                            <div className="modal-footer">
                                {validationMessages.enable && <label className= "validation-lbl" style={{ color: validationMessages.color } }>{validationMessages.Messages}</label>}
                                <button className="primary-btn btn-small" onClick={onValidIntegration}>Test</button>
                                <button className={`${!isValidService? "primary-btn btn-small" : "secondary-btn btn-small"}`} disabled={isValidService} onClick={onSaveIntegration}>Save</button>
                            <button className="secondary-btn btn-small" onClick={onCancelClick} >Cancel</button>            
                        </div>}
                    </form>
           
                </div>s
            </div>
        </div>
           
    );

}

export default IntegrationModle;